import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import space from "./space";
import tutorial from "./tutorial";
import traduction from "./traduction";
import user from "./user"

const rootReducer = combineReducers({
  customizer,
  auth,
  space,
  tutorial,
  traduction,
  user
});

export default rootReducer;
