import React from "react";
import classnames from "classnames";

import { User } from "react-feather";

const Avatar = ({ src, alt, className, size, status, componentProps = {}, ...props }) => {
  const renderStatus = () =>
    status ? (
      <span
        className={classnames({
          [`avatar-status-${status}`]: status,
          [`avatar-status-${size}`]: size,
        })}
      ></span>
    ) : null;

  if (src) {
    return (
      <div className={`avatar m-0 ${className ? className : ""}`} {...props}>
        <img src={src} alt={alt ? alt : ""} width={size} height={size} {...componentProps} />
        {renderStatus()}
      </div>
    );
  }

  if (alt) {
    return (
      <div
        className={`avatar d-flex justify-content-center rounded-circle bg-light align-items-center ${
          className ? className : ""
        }`}
        style={{ width: size, height: size }}
      >
        <span className="font-weight-bold">{alt}</span>
        {renderStatus()}
      </div>
    );
  }

  return (
    <div className={`avatar m-0 ${className ? className : ""}`} {...props}>
      <User style={{ width: size, height: size }} {...componentProps} />
      {renderStatus()}
    </div>
  );
};

export default Avatar;
