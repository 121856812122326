import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Archive, ChevronDown, MapPin, PlusCircle } from "react-feather";
import {
  getCurrentFromState,
  getListFromState,
  requestDelete,
  requestCurrent,
} from "../../../../../redux/actions/space/space";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "components/hibimComponents/Avatar";
import { useTrad } from "../../../../../utility/traduction";

const SidebarSpaces = () => {
  const { current, list } = useSelector(state => ({
    current: getCurrentFromState(state),
    list: getListFromState(state) || [],
  }));
  const t = useTrad("HibimSpaceAdmin_SidebarSpaces");

  // useEffect(() => {console.log("list spaces",list)}, [list])

  const [selectedSpace, setSelectedSpace] = React.useState(null);

  const dispatch = useDispatch();
  const handleDelete = React.useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      dispatch(requestDelete(selectedSpace["@id"]));
      setSelectedSpace(null);
    },
    [dispatch, selectedSpace],
  );

  const handleCurrent = React.useCallback(
    (e, id) => {
      e.stopPropagation();
      e.preventDefault();
      dispatch(requestCurrent(id));
    },
    [dispatch],
  );

  if (!current) {
    return null;
  }
  return (
    <div className="space-action d-block px-1">
      <Modal isOpen={Boolean(selectedSpace)} centered toggle={() => setSelectedSpace(null)}>
        <ModalHeader>
          {t("disconnect", "Deconnexion de")} {selectedSpace ? selectedSpace.name : ""}
        </ModalHeader>
        <ModalBody>
          {t("modalbody1", "Voulez vous réellement vous déconnecter de cet Espace?")} <br />
          {t("modalbody2", "Vous ne pourrez plus accéder à l'ensemble des informations présentes dessus.")}
        </ModalBody>
        <ModalFooter>
          <Button outline color="primary" onClick={() => setSelectedSpace(null)}>
            {t("cancel", "Annuler")}
          </Button>
          <Button color="primary" onClick={handleDelete}>
            {t("disconnect", "Se Déconnecter")}
          </Button>
        </ModalFooter>
      </Modal>
      <UncontrolledDropdown>
        <DropdownToggle color="tag primary" className="w-100">
          <div className="d-flex align-items-center">
            {current.photo && <Avatar src={current.photo?.url} size="20px" className="d-inline-block" />}
            {!current.photo && <Archive size={20} />}
            <span className="ml-1 mr-auto text-bold-600 truncate">{current.name}</span>
            <ChevronDown size={20} />
          </div>
        </DropdownToggle>
        <DropdownMenu right style={{ maxWidth: "230px" }}>
          <DropdownItem header>{t("mySpaces", "Mes Spaces")}</DropdownItem>
          {list.map((space, id) => (
            <DropdownItem
              toggle={false}
              key={id}
              tag="a"
              className="d-flex align-items-center"
              onClick={e => handleCurrent(e, space["@id"])}
            >
              <div className="mr-50">
                {space.photo && <Avatar src={space.photo?.url} size="15px" className="d-inline-block" />}
                {!space.photo && <Archive size={15} />}
              </div>
              <span className="truncate">{space.name}</span>
              {/* <div className=" align-items-center ml-auto" onClick={e => setSelectedSpace(space)}>
                <LogOut size={15} />
              </div> */}
            </DropdownItem>
          ))}
          <DropdownItem header>{t("action", "Action")}</DropdownItem>
          <DropdownItem
            tag="a"
            className="d-flex align-items-center"
            href={`${process.env.REACT_APP_ACCOUNTS_URL}/create?redirect=${encodeURIComponent(window.location.href)}`}
          >
            <MapPin size={15} className="mr-50" />
            <span className="truncate">{t("addSpace", "Ajouter un space")}</span>
          </DropdownItem>
          <DropdownItem
            tag="a"
            className="d-flex align-items-center"
            href={`${process.env.REACT_APP_ACCOUNTS_URL}/join?redirect=${encodeURIComponent(window.location.href)}`}
          >
            <PlusCircle size={14} className="mr-50" />
            <span>{t("joinSpace", "Rejoindre un space")}</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default SidebarSpaces;
