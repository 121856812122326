/**
 *  whether cards should be displayed or not at the top of Dashboard
 * structure {cardName : true/false}
 */

const cardNames = [
	"space",
	"channel",
	"invite",
	"present_activity"
];

const initState = cardNames.reduce((acc, curr) => {
	acc[curr] = true;
	return acc;
}, {});

const dashboard = (state = initState, action) => {
	switch (action.type) {
		case "UPDATE_CARD_DASHBOARD_VISIBILTY":
			return { ...state, [action.payload.cardName]: action.payload.visible };
		default:
			return state;
	}
};

export default dashboard;
