export const ROLE_OWNER = "OWNER";
export const ROLE_ADMIN = "ADMIN";
export const ROLE_MEMBER = "MEMBER";
export const ROLE_GUEST = "GUEST";

export const PERMISSION_WRITE = "write";

export const MEMBER_ROLES = [ROLE_OWNER, ROLE_ADMIN, ROLE_MEMBER, ROLE_GUEST];

export const isOwner = (space, user) =>
  ROLE_OWNER === (space?.memberRole || space?.member_role) || user?.["@id"] === space?.user;
export const isAdmin = (space, user) =>
  isOwner(space, user) || [ROLE_ADMIN].includes(space?.memberRole || space?.member_role);
export const isMember = space => [ROLE_MEMBER].includes(space?.memberRole || space?.member_role);
export const isGuest = space => [ROLE_GUEST].includes(space?.memberRole || space?.member_role);

export const isContentWritable = (content, space, user) => {
  if (isAdmin(space, user)) {
    return true;
  }
  const contentRole = content?.roles?.find(role => role?.user?.["@id"] === user?.["@id"]);
  return contentRole?.role === PERMISSION_WRITE;
};
