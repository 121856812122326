import { RESET_SPACE } from "redux/actions/space";
import { REQUEST_GET_FORMDATA, REQUEST_UPDATE_FORMDATA_STATUS } from "redux/actions/space/formdata";

const INITIAL_STATE = {
  list: null,
  current: null,
  status: {
    getFormData: null,
    updateStatus: null,
  },
};

const componentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_GET_FORMDATA: {
      if (action.success) {
        return { ...state, status: { ...state.status, getFormData: "success" }, list: action.data["hydra:member"] };
      }
      return { ...state, status: { ...state.status, getFormData: action.success === null ? "fetching" : "error" } };
    }
    case REQUEST_UPDATE_FORMDATA_STATUS:
      if (action.success) {
        return {
          ...state,
          status: { ...state.status, updateStatus: "success" },
          list: state.list.map(data => {
            if (data.id === action.data.id) return action.data;
            return data;
          }),
        };
      }
      return { ...state, status: { ...state.status, updateStatus: action.success === null ? "fetching" : "error" } };
    case RESET_SPACE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default componentsReducer;
