import React from "react";
import * as Icon from "react-feather";

import { ROLE_ADMIN, ROLE_GUEST, ROLE_MEMBER, ROLE_OWNER } from "./acl";

const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "My Space",
    menu_key: "mySpace",
  },
  {
    id: "myspace",
    title: "My Space",
    type: "item",
    icon: <Icon.Airplay size={20} />,
    navLink: "/myspace",
    roles: [ROLE_OWNER, ROLE_ADMIN, ROLE_MEMBER, ROLE_GUEST],
    menu_key: "mySpace",
  },
  // {
  //   id: "notification",
  //   title: "Notification",
  //   type: "item",
  //   icon: <Icon.MessageSquare size={20} />,
  //   navLink: "/notification",
  //   roles: [ROLE_OWNER, ROLE_ADMIN, ROLE_MEMBER],
  //   menu_key: "notification",
  // },
  // {
  //   id: "feed",
  //   title: "Feed",
  //   type: "item",
  //   icon: <Icon.CheckSquare size={20} />,
  //   navLink: "/feed",
  //   roles: [ROLE_OWNER, ROLE_ADMIN, ROLE_MEMBER],
  //   menu_key: "feed",
  // },
  {
    id: "action",
    title: "Action",
    type: "item",
    icon: <Icon.CheckSquare size={20} />,
    navLink: "/action",
    roles: [ROLE_OWNER],
    menu_key: "action",
  },
  {
    id: "chat",
    title: "Messages",
    type: "item",
    icon: <Icon.MessageSquare size={20} />,
    navLink: "/messages",
    roles: [ROLE_OWNER, ROLE_ADMIN, ROLE_MEMBER, ROLE_GUEST],
    menu_key: "messages",
  },
  {
    id: "demands",
    title: "Demande",
    type: "item",
    icon: <Icon.CheckSquare size={20} />,
    navLink: "/demandes",
    roles: [ROLE_OWNER, ROLE_ADMIN, ROLE_MEMBER, ROLE_GUEST],
    menu_key: "demands",
  },
  {
    type: "groupHeader",
    groupTitle: "Communication",
    menu_key: "communication",
  },
  {
    id: "site",
    title: "Site Internet",
    type: "external-link",
    icon: <Icon.Globe size={20} />,
    navLink: process.env.REACT_APP_ADMIN_SITE_URL,
    roles: [ROLE_OWNER, ROLE_ADMIN],
    menu_key: "website",
  },
];

export default navigationConfig;
