import React from "react";
import * as Icons from "react-feather";

export const actionsList = [
  {
    id: 0,
    title: "Utilisateurs",
    name: "UsersAction",
    icon: <Icons.Users size={20} className="text-primary" />,
    navLink: "/action/user",
  },
  {
    id: 2,
    title: "Adhésions",
    name: "MembershipsAction",
    icon: <Icons.Users size={20} className="text-primary" />,
    navLink: "/action/membership",
  },
  {
    id: 3,
    title: "Activités",
    name: "ActivitiesAction",
    icon: <Icons.Calendar size={20} className="text-primary" />,
    navLink: "/action/activity",
  },
  {
    id: 4,
    title: "Mes articles de blog",
    name: "ArticlesAction",
    icon: <Icons.AlignJustify size={20} className="text-primary" />,
    navLink: "/action/article",
  },
  {
    id: 5,
    title: "Mes pages",
    name: "PagesAction",
    icon: <Icons.FileText size={20} className="text-primary" />,
    navLink: "/action/page",
  },
  {
    id: 6,
    title: "Mes formulaires",
    name: "FormsAction",
    icon: <Icons.FileText size={20} className="text-primary" />,
    navLink: "/action/form",
  },
  {
    id: 7,
    title: "Portfolio",
    name: "PortfolioAction",
    icon: <Icons.AlignJustify size={20} className="text-primary" />,
    navLink: "/action/portfolio",
  },
  {
    id: 8,
    title: "Statistiques utilisateur",
    name: "UserStatAction",
    icon: <Icons.User size={20} className="text-primary" />,
    navLink: "/action/stats-user",
  },
  {
    id: 9,
    title: "Statistiques formulaires",
    name: "FormStatAction",
    icon: <Icons.Clipboard size={20} className="text-primary" />,
    navLink: "/action/stats-forms",
  },
];

export const getActionTitleByName = name => actionsList.find(action => action.name === name)?.title || name;
