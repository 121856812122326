import axios from "../../../utility/api";

export const setUsers = users => ({type:"SET_USERS", payload:users})

export const fetchAllUsers = () => async (dispatch) => {
  try {
    let users = await axios.get("/api/users").then(res => res.data["hydra:member"])
    dispatch(setUsers(users))
  }
  catch(e)
  {
    console.log("error fetching users", e)
  }
}