import React from "react";
import { Button } from "reactstrap";
import "boxicons"
import {useTrad} from "../../utility/traduction"

const BuyNow = () => {
	const t = useTrad("HibimSpaceAdmin_BuyNow");
	return (
		<div className="buy-now">
			<Button
				tag="a"
				className="d-flex align-items-center px-1"
				style={{height:"38px"}}
				color="danger"
				href={`${
					process.env.REACT_APP_PAYMENT_URL
				}?redirect=${encodeURIComponent(window.location.href)}`}
			>
				<box-icon name="rocket" color="white" size="sm" style={{marginRight:"10px"}}></box-icon>{t("suscribe","Souscrire à un abonnement")}
			</Button>
		</div>
	);
};

export default BuyNow;
