import {
  PUSH_SELECTED_ACTION,
  REMOVE_SELECTED_ACTION,
  UPDATE_SELECTED_ACTION_TAB,
  GET_FROM_BOOKMARKS,
  PUSH_TO_BOOKMARKS,
  SET_ACTIONS
} from "../../../actions/space/action";

const selectedAction = (
  state = {
    selectedActions: []
  },
  action
) => {
  switch (action.type) {
    case SET_ACTIONS: {
      console.log("reducer set actions", action)
      return {...state, selectedActions: action.payload}
    }
    case PUSH_SELECTED_ACTION:
      return {
        ...state,
        selectedActions:[...state.selectedActions, action.selectedAction]
      };
    case REMOVE_SELECTED_ACTION:
      return {
        ...state,
        selectedActions: state.selectedActions.filter(e => e.id !== action.payload.id),
        bookmarks: state.bookmarks
          ? state.bookmarks.filter(
              (el) => el.name !== action.payload.name
            )
          : [],
      };

    case UPDATE_SELECTED_ACTION_TAB:
      return {
        ...state,
        selectedActions: action.selectedActions,
      };

    case GET_FROM_BOOKMARKS:
      return {
        ...state,
        selectedActions: state.selectedActions
          ? [...state.selectedActions, action.selectedAction]
          : [action.selectedAction],
        bookmarks: state.bookmarks
          ? state.bookmarks.filter(
              (el) => el.name !== action.selectedAction.name
            )
          : [],
      };

    case PUSH_TO_BOOKMARKS:
      return {
        ...state,
        selectedActions: state.selectedActions
          ? state.selectedActions.filter(
              (el) => el.name !== action.selectedAction.name
            )
          : [],
        bookmarks: state.bookmarks
          ? [...state.bookmarks, action.selectedAction]
          : [action.selectedAction],
      };
    default:
      return state;
  }
};

export default selectedAction;
