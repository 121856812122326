/**
middlewares methods for redux

We have here differents methods which handle redux behaviour with api communication.

withAuthentication:
- will add the bearer token to the header of the api call

withAuthenticationError:
- will check if the token is no longer valid and dispatch a logout

makeDispatchFetch:
- his is a dispatched fetch method
- this method will be xalled only if your action have "request" field
- example:
 {
   'type':"CREATE_USER",
   'request': {
        'method': 'POST',
        'url': 'https://myapi.com/users',
        'data': {
          'lastname': 'myLastName',
          'firstname': 'myFirstName'
        }
   }
 }

 - this method works in three steps:
      1. success = null => the fetch method is launched
      2. success = true => success fetch
      3. success = false => error in fetch
      If success is undefined, we can launch a fetch method, else, it means that
      one is allready launched and we have to wait
**/

import { getBearerTokenFromState, logout } from "../actions/auth";

/**
 * handle authentication check
 */
export function withAuthentication(fetch, store) {
  return async (url, options = {}) => {
    const headers = (options.headers = options.headers || {});
    headers["Authorization"] = `Bearer ${getBearerTokenFromState(
      store.getState()
    )}`;
    return fetch(url, options);
  };
}

/**
 * clear auth state if unvalid token
 **/
export function withAuthenticationError(fetch, store) {
  return async (url, options) => {
    const response = await fetch(url, options);
    if (!response.ok && response.status === 401) {
      store.dispatch(logout());
    }
    return response;
  };
}

/**
 * return json data if exists, else null
 * @param {Response} response - our API response
 * @returns {Object | null} - our json response
 **/
function getDataFromResponse(response) {
  const contentType = response.headers.get("Content-Type") || "";
  if (
    contentType.indexOf("application/json") === -1 &&
    contentType.indexOf("application/ld+json") === -1
  ) {
    return null;
  }
  return response.json();
}

function makeDispatchFetch(fetch, store) {
  return async (action) => {
    const { url, ...options } = action.request;
    const headers = (options.headers = options.headers || {});
    headers["Content-Type"] = "application/json";

    if (options.method && options.method !== "GET") {
      options.body = options.data ? JSON.stringify(options.data) : {};
    }
    delete options.data;

    const request = fetch(url, options);

    store.dispatch({
      ...action,
      success: null,
      request: request,
    });

    let response = null;
    try {
      response = await request;
      if (!response.ok) {
        throw new Error(response);
      }
      const data = await getDataFromResponse(response);
      store.dispatch({
        ...action,
        success: true,
        request: request,
        response: response,
        data,
      });
    } catch (err) {
      store.dispatch({
        ...action,
        success: false,
        request: request,
        response: response,
        meta: err,
      });
    }
  };
}

export function APIMiddleware(fetch = global.fetch, connectors) {
  connectors = connectors || APIMiddleware.connectors;
  return (store) => {
    fetch = connectors
      .reverse()
      .reduce((f, connector) => connector(f, store), fetch);

    const dispatchFetch = makeDispatchFetch(fetch, store);
    return (next) => (action) => {
      if (action.request && action.success === undefined) {
        return dispatchFetch(action);
      }
      return next(action);
    };
  };
}

APIMiddleware.connectors = [withAuthenticationError, withAuthentication];
