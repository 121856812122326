import { RESET_SPACE } from "redux/actions/space";
import {
  REQUEST_LIST,
  REQUEST_CREATE,
  REQUEST_UPDATE,
  REQUEST_DELETE,
  FILTER_LIST,
  REQUEST_ACTIVITIES_CATEGORIES_LIST,
  REQUEST_ACTIVITIES_CATEGORIES_ADD,
  RESET_UPSERT,
} from "../../../actions/space/activities";

const INITIAL_STATE = {
  activities: null,
  allActivities: null,
  current: null,
  filter: "all",
  categories: null,
  status: {
    getActivities: null,
    addActivity: null,
    updateActivity: null,
    deleteActivity: null,
    getCategories: null,
    addCategory: null,
  },
};

const activities = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      if (!action.success) {
        return {
          ...state,
          status: { ...state.status, getActivities: action.success === null ? "fetching" : "error" },
        };
      }

      let allActivities = action?.data?.["hydra:member"] || [];
      return {
        ...state,
        status: { ...state.status, getActivities: "success" },
        allActivities,
        filter: "all",
        activities: allActivities.filter(activity => !activity.isDeleted),
      };

    case REQUEST_CREATE:
      if (!action.success) {
        return {
          ...state,
          status: { ...state.status, addActivity: action.success === null ? "fetching" : "error" },
        };
      }

      const newAllActivities = state.allActivities ? [...state.allActivities, action.data] : [action.data];
      return {
        ...state,
        status: { ...state.status, addActivity: "success" },
        allActivities: newAllActivities,
        filter: "all",
        activities: newAllActivities.filter(activity => !activity.isDeleted),
      };

    case REQUEST_DELETE:
      if (!action.success) {
        return {
          ...state,
          status: { ...state.status, deleteActivity: action.success === null ? "fetching" : "error" },
        };
      }

      return {
        ...state,
        status: { ...state.status, deleteActivity: "success" },
        activities: state.activities.filter(a => a.id !== action.id),
      };

    case REQUEST_UPDATE:
      if (!action.success) {
        return {
          ...state,
          status: { ...state.status, updateActivity: action.success === null ? "fetching" : "error" },
        };
      }

      const instance = action.data || {};
      let d = state.allActivities || [];
      const instanceIndex = d.findIndex(val => val.id === instance.id);
      if (instanceIndex !== -1) {
        d = d.slice();
        d.splice(instanceIndex, 1, instance);
      }

      state = {
        ...state,
        status: { ...state.status, updateActivity: "success" },
        allActivities: d,
      };
      action.type = FILTER_LIST;
      action.filter = state.filter || "all";

    // eslint-disable-next-line no-fallthrough
    case FILTER_LIST:
      if (!state.allActivities) {
        return {
          ...state,
          filter: "all",
          activities: [],
        };
      }
      if (action.filter.includes("label=")) {
        const labels = action.filter.split("=")[1].split(";");
        return {
          ...state,
          filter: "label",
          activities: state.allActivities.filter(
            activity =>
              activity.Labels && activity.Labels.length && activity.Labels.some(label => labels.indexOf(label) !== -1),
          ),
        };
      }

      switch (action.filter) {
        case "all":
          return {
            ...state,
            filter: "all",
            activities: state.allActivities.filter(activity => !activity.isDeleted),
          };

        case "favorite":
          return {
            ...state,
            filter: "favorite",
            activities: state.allActivities.filter(activity => activity.isFavorite === true),
          };

        case "deleted":
          return {
            ...state,
            filter: "deleted",
            activities: state.allActivities.filter(activity => activity.isDeleted === true),
          };

        case "complete":
          return {
            ...state,
            filter: "complete",
            activities: state.allActivities.filter(activity => activity.isDone === true),
          };

        default:
          return state;
      }

    case REQUEST_ACTIVITIES_CATEGORIES_LIST:
      if (action.success) {
        return {
          ...state,
          status: { ...state.status, getCategories: "success" },
          categories: action.data["hydra:member"],
        };
      }
      return { ...state, status: { ...state.status, getCategories: action.success === null ? "fetching" : "error" } };

    case REQUEST_ACTIVITIES_CATEGORIES_ADD: {
      if (action.success) {
        return {
          ...state,
          status: { ...state.status, addCategory: "success" },
          categories: [...state.categories, action.data],
        };
      }
      return { ...state, status: { ...state.status, addCategory: action.success === null ? "fetching" : "error" } };
    }

    case RESET_UPSERT: {
      return {
        ...state,
        status: { ...state.status, updateActivity: null, addCategory: null },
      };
    }

    case RESET_SPACE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default activities;
