import {
  REQUEST_LIST,
  REQUEST_CURRENT,
  REQUEST_DELETE,
  REQUEST_MEMBERS,
  CLEAR,
  UPDATE_MEMBER,
  REQUEST_SEARCH_TERM,
  UPDATE_CURRENT_SPACE,
} from "../../../actions/space/space";

const INITIAL_STATE = {
  loading: null,
  currentLoading: null,
  membersLoading: null,
};

const space = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      if (action.success === null) {
        return {
          ...state,
          loading: true,
        };
      }

      if (action.success === false) {
        return {
          ...state,
          loading: false,
        };
      }

      let spaces = [];

      if (action.data && action.data["hydra:member"] && action.data["hydra:member"].length) {
        spaces = action.data["hydra:member"];
        // map((instance) => ({
        //   member_id: instance["@id"],
        //   ...instance.space,
        // }));
      }
      // console.log("REQUEST_LIST space", action.space)
      let current = spaces.find(space => space.slug === (action.space || state.current?.slug)) || spaces[0];
      return {
        ...state,
        list: spaces,
        current: current,
        loading: false,
      };

    case REQUEST_CURRENT:
      if (action.success === null) {
        return {
          ...state,
          currentLoading: true,
        };
      }

      if (action.success === false) {
        return {
          ...state,
          currentLoading: false,
          current: null,
        };
      }

      return {
        ...state,
        currentLoading: false,
        current: action.data,
      };

    case REQUEST_DELETE:
      if (action.success === null) {
        return {
          ...state,
          deleting: true,
        };
      }
      if (action.success === false) {
        return {
          ...state,
          deleting: false,
        };
      }

      const newList = state.list.filter(space => space["@id"] !== action.payload.id);
      return {
        ...state,
        deleting: false,
        current: state.current["@id"] === action.payload.id ? newList[0] : state.current,
        list: newList,
      };

    case REQUEST_MEMBERS:
      if (action.success === null) {
        return {
          ...state,
          membersLoading: true,
        };
      }

      if (action.success === false) {
        return {
          ...state,
          membersLoading: false,
        };
      }

      return {
        ...state,
        members: Object.entries(action.data).map(([_, member]) => member),
        membersLoading: false,
      };

    case UPDATE_MEMBER:
      if (action.success) {
        return {
          ...state,
          status: { ...state.status, updateMember: "success" },
          members: state.members.map(m => {
            if (m.member_id === action.data.id) {
              return {
                ...m,
                is_team: action.data.isTeam,
                member_role: action.data.role,
              };
            }
            return m;
          }),
        };
      }
      return { ...state, status: { ...state.status, updateMember: action.success === null ? "fetching" : "error" } };

    case REQUEST_SEARCH_TERM: {
      if (!action.success) {
        return { ...state, searching: action.success === null };
      }
      return {
        ...state,
        searchResults: action.data["hydra:member"],
        searching: false,
      };
    }

    case CLEAR:
      return {
        ...state,
        current: null,
        list: [],
        deleting: false,
        currentLoading: false,
        loading: false,
      };
    case UPDATE_CURRENT_SPACE: {
      if (action.status === "success") {
        let newCurrentSpace = { ...state.current, ...action.data };

        // update spaces list as well
        let spaces = state.list.map(space => {
          if (space.id === newCurrentSpace.id) {
            return { ...space, ...newCurrentSpace };
          } else {
            return space;
          }
        });
        return {
          ...state,
          current: newCurrentSpace,
          list: spaces,
        };
      } else return state;
    }

    default:
      return state;
  }
};

export default space;
