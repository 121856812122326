import { getCurrentUserFromState } from "../../auth";
import { getCurrentFromState as getCurrentSpaceFromState } from "../space";

export const REQUEST_LIST = "DEMANDS.REQUEST_LIST";
export const REQUEST_CREATE = "DEMANDS.REQUEST_CREATE";
export const REQUEST_UPDATE = "DEMANDS.REQUEST_UPDATE";
export const FILTER_LIST = "DEMANDS.FILTER_LIST";

export function requestList() {
  return (dispatch, getState) => {
    const state = getState();
    const currentSpace = getCurrentSpaceFromState(state);
    if (!currentSpace) {
      return null;
    }
    const currentSpaceId = currentSpace["@id"].replace("/api/space/", "");
    dispatch({
      type: REQUEST_LIST,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/tasks?space=${currentSpaceId}`,
        method: "GET",
      },
    });
    dispatch(filterList("all"));
  };
}

export function requestCreate(data) {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = getCurrentUserFromState(state);
    const currentSpace = getCurrentSpaceFromState(state);
    if (!currentUser) {
      return null;
    }

    dispatch({
      type: REQUEST_CREATE,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/task`,
        method: "POST",
        data: {
          ...data,
          space: currentSpace ? currentSpace["@id"] : null,
          Creator: currentUser["@id"],
        },
      },
    });
    dispatch(filterList("all"));
  };
}

export function requestUpdate(id, data) {
  return {
    type: REQUEST_UPDATE,
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/task/${id}`,
      method: "PUT",
      data,
    },
  };
}

export function filterList(filter) {
  return {
    type: FILTER_LIST,
    filter,
  };
}

export function getDemandsFromState(state) {
  if (state.space && state.space.demands) {
    return state.space.demands.demands || [];
  }
  return [];
}

export function getListPendingFromState(state) {
  if (state.space && state.space.demands) {
    return state.space.demands.listPending || false;
  }
  return false;
}

export function getCreatePendingFromState(state) {
  if (state.space && state.space.demands) {
    return state.space.demands.createPending || false;
  }
  return false;
}

export function getFilterTypeFromState(state) {
  if (state.space && state.space.demands) {
    return state.space.demands.filter || null;
  }
  return null;
}
