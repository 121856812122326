import {
  REQUEST_AUTH,
  SUCCESS_AUTH,
  ERROR_AUTH,
  CLEAR,
  GET_CURRENT_USER,
  UPDATE_CURRENT_USER,
} from "../../actions/auth";

const connect = (state = {}, action) => {
  switch (action.type) {
    case SUCCESS_AUTH:
      return {
        ...state,
        ...action.auth,
      };

    case REQUEST_AUTH:
      return {
        ...state,
        ...action.auth,
      };

    case ERROR_AUTH:
      return {
        ...state,
        ...action.auth,
      };

    case CLEAR:
      return {
        ...state,
        ...action.auth,
      };

    case GET_CURRENT_USER:
      if (!action.success) {
        return { ...state, userLoading: action.success === null };
      }
      return {
        ...state,
        user: action.data,
        userLoading: false,
      };

    case UPDATE_CURRENT_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      };

    default:
      return state;
  }
};

export default connect;
