import fp from "lodash/fp";
import { RESET_SPACE } from "redux/actions/space";

import {
  REQUEST_GET_TEMPLATE,
  REQUEST_GET_TEMPLATES,
  REQUEST_GET_PAGES,
  REQUEST_GET_PAGE,
  REQUEST_UPDATE_PAGE,
  REQUEST_ADD_PAGE,
  REQUEST_REMOVE_PAGE,
  REQUEST_GET_PAGE_ELEMENTS,
  REQUEST_UPDATE_PAGE_ELEMENT,
  REQUEST_ADD_PAGE_ELEMENT,
  REQUEST_REMOVE_PAGE_ELEMENT,
} from "../../../actions/space/pages";

const INITIAL_STATE = {
  pages: [],
  current: null,
  elements: [],
  templates: [],
  status: {
    getPages: null,
    getPage: null,
    getElements: null,
    getTemplate: null,
    getTemplates: null,
  },
};

const componentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_GET_TEMPLATE: {
      if (action.success) {
        return { ...state, status: { ...state.status, getTemplate: "success" }, template: action.data };
      }
      return { ...state, status: { ...state.status, getTemplate: action.success === null ? "fetching" : "error" } };
    }
    case REQUEST_GET_TEMPLATES: {
      if (action.success) {
        return {
          ...state,
          status: { ...state.status, getTemplates: "success" },
          templates: action.data["hydra:member"],
        };
      }
      return { ...state, status: { ...state.status, getTemplates: action.success === null ? "fetching" : "error" } };
    }
    case REQUEST_GET_PAGE: {
      if (action.success) {
        return { ...state, status: { ...state.status, getPage: "success" }, current: action.data };
      }
      return { ...state, status: { ...state.status, getPage: action.success === null ? "fetching" : "error" } };
    }
    case REQUEST_ADD_PAGE: {
      if (action.success) {
        return { ...state, status: { ...state.status, addPage: "success" }, pages: [...state.pages, action.data] };
      }
      return { ...state, status: { ...state.status, addPage: action.success === null ? "fetching" : "error" } };
    }
    case REQUEST_UPDATE_PAGE: {
      if (action.success) {
        return { ...state, status: { ...state.status, updatePage: "success" }, current: action.data };
      }
      return { ...state, status: { ...state.status, updatePage: action.success === null ? "fetching" : "error" } };
    }
    case REQUEST_REMOVE_PAGE: {
      if (action.success) {
        return {
          ...state,
          status: { ...state.status, removePage: "success" },
          pages: state.pages.filter(page => page.id !== action.id),
        };
      }
      return { ...state, status: { ...state.status, removePage: action.success === null ? "fetching" : "error" } };
    }
    case REQUEST_GET_PAGES: {
      if (action.success) {
        return { ...state, status: { ...state.status, getPages: "success" }, pages: action.data["hydra:member"] };
      }
      return { ...state, status: { ...state.status, getPages: action.success === null ? "fetching" : "error" } };
    }
    case REQUEST_GET_PAGE_ELEMENTS: {
      if (action.success) {
        return { ...state, status: { ...state.status, getElements: "success" }, elements: action.data["hydra:member"] };
      }
      return { ...state, status: { ...state.status, getElements: action.success === null ? "fetching" : "error" } };
    }
    case REQUEST_ADD_PAGE_ELEMENT:
      let elements;
      if (action.success) {
        const parentId = action.element.parent;
        if (parentId) {
          elements = fp.map(elt => {
            if (elt["@id"] === parentId) {
              return {
                ...elt,
                elements: [...elt.elements, action.data],
              };
            }
            return elt;
          })(state.elements);
        } else {
          elements = [...state.elements, action.data];
        }
        return { ...state, status: { ...state.status, addElement: "success" }, elements };
      }
      return { ...state, status: { ...state.status, addElement: action.success === null ? "fetching" : "error" } };
    case REQUEST_UPDATE_PAGE_ELEMENT: {
      if (action.success) {
        const elements = fp.map(elt => {
          if (elt.id === action.data.id) {
            return { ...elt, ...action.data };
          }
          const subelements = fp.map(sub => {
            if (sub.id === action.data.id) {
              return { ...sub, ...action.data };
            }
            return sub;
          })(elt.elements);
          return { ...elt, elements: subelements };
        })(state.elements);
        return { ...state, status: { ...state.status, updateElement: "success" }, elements };
      }
      return { ...state, status: { ...state.status, updateElement: action.success === null ? "fetching" : "error" } };
    }
    case REQUEST_REMOVE_PAGE_ELEMENT:
      if (action.success) {
        const elements = fp
          .map(elt => {
            return {
              ...elt,
              elements: elt.elements.filter(sub => sub.id !== action.id),
            };
          })(state.elements)
          .filter(elt => elt.id !== action.id);
        return { ...state, status: { ...state.status, removeElement: "success" }, elements };
      }
      return { ...state, status: { ...state.status, removeElement: action.success === null ? "fetching" : "error" } };

    case RESET_SPACE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default componentsReducer;
