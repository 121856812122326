import { combineReducers } from "redux";
import action from "./action";
import chat from "./chat";
import demands from "./demands";
import articles from "./articles";
import activities from "./activities";
import portfolio from "./portfolio";
import space from "./space";
import dashboard from "./dashboard";
import components from "./components";
import pages from "./pages";
import formdata from "./formdata";

const spaceReducer = combineReducers({
  action,
  chat,
  demands,
  articles,
  activities,
  portfolio,
  space,
  dashboard,
  components,
  pages,
  formdata,
});

export default spaceReducer;
