import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, {keyframes, css} from "styled-components";


const borderAnimation = keyframes`
  from {
    border: 2px #fc6f03 solid;
    
  }

  to {
    border: 2px #fcc59a solid;
  }
`;

const TradElement = styled.span`
	border-radius: 4px;
	padding: ${props => props.active && "5px"};
	display:  ${props => props.active ? "block": "inline"};
	animation: ${props => props.active && css`${borderAnimation} 1s linear infinite alternate`}
`;


/**
 * Locales located in src/assets/locales : fr.json...
 * format of json data : {"{projectId}_{pageId}_{ComponentId}_{keyword}" : "foo..."}
 * an example : "MyProject_contact_ContactForm_title" : "Title contact form"
 * the keyword here is title
 * IMPORTANT :
 * 1 - Uppercase is reserved for components and project id !
 * 2 - "_" acts as a separator and should not be used in any page, project, component or keyword naming. Use
 * Camel case
 */

/**
 * A custom traduction hook
 * @param  {string} rootKey The rootKey with this format "{projectID}_{pageID}_{ComponentId}"
 * @param  {Object} tradOptions you can specify for instance a locale
 * @return {function()} The traduction function
 **/
const useTrad = (rootKey, { path, lang:customLang } = {}) => {
	const [data, setData] = useState({});
	const storeLang = useSelector(state => state.traduction.lang);
	const [lang, setLang] = useState("fr");
	const [active, setActive] = useState(); // query param

	const loadTraduction = async lang => {
		try {
			// TODO : resolve root path (webpack issue with dynamic root...)
			console.log("loadTrad", lang)
			let data = await import("../assets/locales/"+lang+".json").then(res => res.default);
			return data;
		} catch (error) {
			console.log("error loading file : ", error);
		}
	}

	useEffect(() =>{
		let newLang = customLang || storeLang; // priority : lang in params then redux store
		if(newLang && newLang !== lang && typeof newLang === "string")
		{
			setLang(newLang)
		}
	}, [storeLang, customLang, lang])
	/**
	 * Retrieve active query param ?active="Test.main.title"
	 * and update state
	 */
	useEffect(() => {
		const params = new URLSearchParams(decodeURIComponent(window.location.search));
		const activeParam = params.get("active");
		if (activeParam) {
			setActive(activeParam.toLowerCase());
		}
	}, []);

	const fetchData = useCallback(async () => {
		let res = await loadTraduction(lang); // {"HibimSpace.dashboard.title": "Un titre",...}
		if (res) {

			// Transform res to {[keyword]:"...trad..."}
			let output = Object.keys(res).reduce((acc, key) => {
				let re = /_[^_]*$/g;
				let newKey = key.replace(re, "");
				// console.log("rootKey",rootKey);
				if (newKey.toLowerCase() === rootKey.toLowerCase()) {
					let particles = key.split("_");
					if (particles.length > 0) {
						let keyword = particles[particles.length - 1];
						// console.log("newKeyword !", keyword);
						keyword = String(keyword).toLowerCase();
						acc[keyword] = res[key];
					}
				}
				return acc;
			}, {});
	
			setData(output);
		}
	}, [lang, rootKey]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	/**
	 * Traduction function
	 * @param {String} keyword The keyword like "title"
	 * @param {String} [defaultTrad = ""] defaultTrad The default traduction in case nothing exist in locale file
	 * @param {Boolean} [displayAsText = false] displayAsText Wether or not we should return a component or a text
	 * @return {React.Component|String}
	 */
	const trad = useCallback(
		(keyword = "", defaultTrad = "", exportAsText = true, variables = {}) => {
			let resTrad = (keyword && data[keyword.toLowerCase()]) || defaultTrad;
			// console.log("traduction called with keyword", keyword)
			// console.log("current data", data);
			if(exportAsText)
			{
				Object.entries(variables).forEach(([key, value]) => {
					resTrad = resTrad.replace(`{${key}}`, value)
				})
				
				return resTrad;
			}
			let isActive = active && `${rootKey}_${keyword}`.toLowerCase() === active;
			return <TradElement active={isActive}>{resTrad}</TradElement>;
		},
		[data, rootKey, active],
	);

	return trad;
};


/**
 * Traduction Module HOC
 * @param {string} rootKey The rootKey with this format "{projectID}_{pageID}_{ComponentId}"
 * @param {Object} [tradOptions = {}] tradOptions, you can specify for instance a locale
 * @return {React.Component} a react Component
 */
const withTrad = (rootKey, options = {}) => Component => {
	return props => {
		let trad = useTrad(rootKey, options);
		return <Component {...props} trad={trad} />;
	};
};
export { withTrad, useTrad };
