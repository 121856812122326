import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getCurrentUser } from "redux/actions/auth";

import Spinner from "./components/@vuexy/spinner/Loading-spinner";
import { ContextLayout } from "./utility/context/Layout";

// Route-based code splitting

const Dashboard = lazy(() => import("./views/pages/space/Dashboard"));

const Action = lazy(() => import("./views/pages/space/action/Action"));

const Shop = lazy(() => import("./views/pages/space/Shop"));

const ProductDetails = lazy(() => import("./views/pages/space/ProductDetails"));

const ShopProcess = lazy(() => import("./views/pages/space/ShopProcess"));

const Success = lazy(() => import("./views/pages/space/Success"));

const Demands = lazy(() => import("./views/pages/space/demands/Demands"));

const Document = lazy(() => import("./views/pages/space/document/Document"));

const Explorer = lazy(() => import("./views/pages/space/explorer/Explorer"));

const Feed = lazy(() => import("./views/pages/space/feed/Feed"));

const Chat = lazy(() => import("./views/pages/space/chat/Chat"));

const Notification = lazy(() => import("./views/pages/space/notification/Notification"));

const Plus = lazy(() => import("./views/pages/space/plus/Plus"));

const ElementsPage = lazy(() => import("./views/pages/space/pages/ElementsPage"));

const Error404 = lazy(() => import("./views/misc/error/404"));

// Set Layout and Component Using App Route
const AppRoute = ({ component: Component, fullLayout, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return (
        <ContextLayout.Consumer>
          {context => {
            let LayoutTag =
              fullLayout === true
                ? context.fullLayout
                : context.state.activeLayout === "horizontal"
                ? context.horizontalLayout
                : context.VerticalLayout;
            return (
              <LayoutTag {...props}>
                <Suspense fallback={<Spinner />}>
                  <Component {...props} />
                </Suspense>
              </LayoutTag>
            );
          }}
        </ContextLayout.Consumer>
      );
    }}
  />
);

const Routes = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser()); // refresh user counters on location change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Switch>
      <AppRoute exact path="/" component={Dashboard} />
      <AppRoute exact path="/myspace" component={Dashboard} />
      <AppRoute exact path="/shop" component={Shop} />
      <AppRoute exact path="/product-detail" component={ProductDetails} />
      <AppRoute exact path="/shop-steps" component={ShopProcess} />
      <AppRoute exact path="/success" component={Success} />
      <AppRoute exact path="/action" component={Action} />
      <AppRoute exact path="/action/:name" component={Action} />
      <AppRoute exact path="/demandes" component={Demands} />
      <AppRoute exact path="/document" component={Document} />
      <AppRoute exact path="/explorer" component={Explorer} />
      <AppRoute exact path="/feed" component={Feed} />
      <AppRoute exact path="/messages" component={Chat} />
      <AppRoute exact path="/notification" component={Notification} />
      <AppRoute exact path="/plus" component={Plus} />

      {/* Pages and Forms */}
      <AppRoute exact path="/pages/:id" component={ElementsPage} />

      <Route component={Error404} />
    </Switch>
  );
};

class AppRouter extends React.Component {
  render() {
    return (
      // Set the directory path if you are deploying in sub-folder
      <Router basename={process.env.REACT_APP_BASENAME}>
        <Routes />
      </Router>
    );
  }
}

export default AppRouter;
