/**
 *  whether tutorial modals should be displayed or not
 * structure {pageName : true/false}
 */

const pageNames = [
	"mySpace",
	"chat",
	"notification",
	"feed",
	"document",
	"explorer",
	"demands",
	"action"
];
const initState = pageNames.reduce((acc, curr) => {
	acc[curr] = false;
	return acc;
}, {});

const tutorialReducer = (state = initState, action) => {
	switch (action.type) {
		case "UPDATE_MODAL_VISIBILTY":
			return { ...state, [action.payload.modalName]: action.payload.visible };
	    case "UPDATE_MODALS":
	    {
	    	return {...state, ...action.payload};
	    }
		default:
			return state;
	}
};

export default tutorialReducer;
