export const TITLE = "Title";
export const PARAGRAPH = "Paragraph";
export const IMAGE = "Image";
export const ACTIVITY = "Activity";
export const BLOG = "Blog";
export const PORTFOLIO = "Portfolio";
export const TEAM = "Team";
export const FORM = "Form";
export const FORM_HEAD = "FormHead";
export const FIELD_TEXT = "FieldText";
export const FIELD_TEXTAREA = "FieldTextarea";
export const FIELD_NUMBER = "FieldNumber";
export const FIELD_CHECKBOX = "FieldCheckbox";
export const FIELD_EMAIL = "FieldEmail";
export const FIELD_PHONE = "FieldPhone";
export const FIELD_MEDIA = "FieldMedia";

export const ELEMENTS_TYPES = [
  TITLE,
  IMAGE,
  PARAGRAPH,
  ACTIVITY,
  BLOG,
  PORTFOLIO,
  TEAM,
  FORM,
  FIELD_TEXT,
  FIELD_TEXTAREA,
  // FIELD_SELECT,
  FIELD_EMAIL,
  FIELD_PHONE,
  FIELD_MEDIA,
  FIELD_CHECKBOX,
  FIELD_NUMBER,
  FORM_HEAD,
];

export const ELEMENTS_TYPES_ICONS = {
  [TITLE]: "font-family",
  [IMAGE]: "image-alt",
  [PARAGRAPH]: "left-indent",
  [ACTIVITY]: "trending-up",
  [BLOG]: "message-edit",
  [PORTFOLIO]: "briefcase",
  [TEAM]: "group",
};

export const PAGE_TYPE_FORM = "form";
export const PAGE_TYPE_PAGE = "page";
export const PAGE_TYPE_MENU = "menu";

export const PAGE_STATUS_PUBLISHED = "published";
export const PAGE_STATUS_DRAFT = "draft";

export const FORM_STATUS_PENDING = "pending";
export const FORM_STATUS_ACCEPTED = "accepted";
export const FORM_STATUS_REJECTED = "rejected";

export const TEMPLATE_MEMBERSHIP = "membership";
export const TEMPLATE_CONTACT = "contact";
export const TEMPLATE_HOME = "home";
export const TEMPLATE_CUSTOM_PAGE = "custom-page";
export const TEMPLATE_CUSTOM_FORM = "custom-form";

export const PAGE_TEMPLATES = [TEMPLATE_MEMBERSHIP, TEMPLATE_CONTACT, TEMPLATE_CUSTOM_PAGE, TEMPLATE_CUSTOM_FORM];
