import { RESET_SPACE } from "redux/actions/space";
import {
  REQUEST_GET_DASHBOARDS,
  REQUEST_UPDATE_COMPONENTS,
  SET_CURRENT_DASHBOARD,
  ADD_DASHBOARD,
  ADD_DASHBOARD_COMPONENT,
  REMOVE_DASHBOARD_COMPONENT,
} from "redux/actions/space/dashboard";

const INITIAL_STATE = {
  dashboards: [
    {
      id: 0,
      name: "Accueil",
    },
  ],
  current: null,
};

export default function dashboardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_GET_DASHBOARDS: {
      return { ...state, dashboards: action.dashboards };
    }
    case ADD_DASHBOARD: {
      return { ...state, dashboards: [...state.dashboards, action.dashboard] };
    }
    case SET_CURRENT_DASHBOARD: {
      return { ...state, current: action.dashboard };
    }
    case REQUEST_UPDATE_COMPONENTS: {
      return { ...state, current: { ...state.current, components: action.components } };
    }
    case ADD_DASHBOARD_COMPONENT: {
      return { ...state, current: { ...state.current, components: [action.component, ...state.current.components] } };
    }
    case REMOVE_DASHBOARD_COMPONENT: {
      return {
        ...state,
        current: {
          ...state.current,
          components: state.current.components.filter(comp => comp.name !== action.component),
        },
      };
    }
    case RESET_SPACE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
