import axios from "../../../../utility/api";

/**
 * update a tutorial modal visibility
 * @param Boolean visible
 */
export const updateModalVisibility = (modalName, visible = false) => async (
	dispatch,
	getState
) => {
	try {
		dispatch({
			type: "UPDATE_MODAL_VISIBILTY",
			payload: { modalName, visible },
		});

		let modals = getState().tutorial.modal;
		let userID = getState().auth.user.id;

		let viewedModals = Object.entries(modals)
			.filter(([key, value]) => !value)
			.map(([key, value]) => key);


		let res = await axios.put(`/api/user/${userID}`, { viewedModals });
		return res;
	} catch (e) {
		console.log("error updating modal visibility");
	}
};

export const updateModals = (modals) => {
	return { type: "UPDATE_MODALS", payload: modals };
};

/**
 * Retrieve already viewed modals from connected user
 */
export const getModalsFromUser = () => (dispatch, getState) => {
	let user = getState().auth.user;
	const pageNames = [
	"mySpace",
	"chat",
	"notification",
	"feed",
	"document",
	"explorer",
	"demands",
	"action"
	];
	if (user && user.viewedModals) {
		let difference = pageNames.filter(modal => !user.viewedModals.includes(modal));
		let data = difference.reduce((acc, cur) => {
			acc[cur] = true;
			return acc;
		}, {});
		dispatch(updateModals(data));
	}
};
