import {
  REQUEST_CREATE_CHANNEL,
  REQUEST_LIST_CHANNELS,
  REQUEST_UPDATE_CHANNEL,
  REQUEST_SEND_DEMAND,
  REQUEST_DELETE_DEMAND,
  REQUEST_LIST_MESSAGES,
  SET_CURRENT_CHAT,
  REQUEST_CREATE_MESSAGE,
  REQUEST_APPROVE_DEMAND,
  REQUEST_DELETE_CHANNEL,
  CLEAR_MESSAGES,
  REQUEST_REJECT_DEMAND,
  REQUEST_LIST_CHATS,
  REQUEST_CONTACTS,
  ADD_CONTACT,
  UPDATE_CONTACT,
  REQUEST_SEARCH_USERS,
} from "../../../actions/space/chat";

const INITIAL_STATE = {
  channelCreating: null,
  channelDeleting: null,
  channelDemanding: null,
  status: {
    getContacts: null,
    addContact: null,
    updateContact: null,
  },
};

const chat = (state = INITIAL_STATE, action) => {
  let c = null;
  let instanceIndex = null;
  let instance = null;
  let messages = null;

  switch (action.type) {
    case REQUEST_LIST_CHATS: {
      if (!action.success) {
        return { ...state, getChats: action.success === null };
      }
      return {
        ...state,
        getChats: true,
        channels: action.data?.channels?.["hydra:member"] || [],
        contacts: action.data?.contacts?.["hydra:member"] || [],
      };
    }

    case REQUEST_SEARCH_USERS: {
      if (!action.success) {
        return { ...state, searchUser: action.success === null };
      }
      return {
        ...state,
        searchUser: true,
        users: action.data?.["hydra:member"] || [],
      };
    }

    case REQUEST_CREATE_CHANNEL:
      if (action.success === null) {
        return {
          ...state,
          channelCreating: true,
        };
      }
      if (!action.success) {
        return {
          ...state,
          channelCreating: false,
        };
      }

      return {
        ...state,
        channelCreating: false,
        channels: state.channels ? [...state.channels, action.data] : [action.data],
      };

    case REQUEST_UPDATE_CHANNEL:
      if (action.success === null) {
        return {
          ...state,
          channelCreating: true,
        };
      }
      if (!action.success) {
        return {
          ...state,
          channelCreating: false,
        };
      }

      instance = action.data || {};
      c = state.channels || [];
      instanceIndex = c.findIndex(val => val.id === instance.id);
      if (instanceIndex !== -1) {
        c = c.slice();
        c.splice(instanceIndex, 1, instance);
      }
      return {
        ...state,
        channelCreating: false,
        channels: c,
      };

    case REQUEST_DELETE_CHANNEL:
      if (action.success === null) {
        return {
          ...state,
          channelDeleting: true,
        };
      }
      if (action.success === false) {
        return {
          ...state,
          channelDeleting: false,
        };
      }

      return {
        ...state,
        channelDeleting: false,
        channels: state.channels ? state.channels.filter(channel => channel["@id"] !== action.payload.idChannel) : [],
      };

    case REQUEST_LIST_CHANNELS: {
      if (!action.success) {
        return { ...state, channelLoading: action.success === null };
      }

      const channels = action.data && action.data["hydra:member"] ? action.data["hydra:member"] : [];
      return {
        ...state,
        channelLoading: false,
        channels,
      };
    }

    case REQUEST_SEND_DEMAND: {
      if (!action.success) {
        return { ...state, channelDemanding: action.success === null };
      }

      return {
        ...state,
        channelDemanding: false,
        channels: state.channels.map(c => {
          if (c["@id"] === action.payload.idChannel) {
            return { ...c, isMember: true, status: action.data.status, channelUsers: [...c.channelUsers, action.data] };
          }
          return c;
        }),
      };
    }
    case REQUEST_DELETE_DEMAND: {
      if (!action.success) {
        return { ...state, channelDemanding: action.success === null };
      }
      return {
        ...state,
        channelDemanding: false,
        channels: state.channels.map(c => {
          if (c.id === action.payload.idChannel) {
            return {
              ...c,
              channelUsers: c.channelUsers.filter(cu => cu.id !== action.payload.idDemand),
            };
          }
          return c;
        }),
      };
    }
    case REQUEST_APPROVE_DEMAND:
    case REQUEST_REJECT_DEMAND: {
      if (!action.success) {
        return { ...state, channelDemanding: action.success === null };
      }

      return {
        ...state,
        channelDemanding: false,
        channels: state.channels.map(c => {
          if (c.id === action.payload.idChannel) {
            return {
              ...c,
              status: action.data.status,
              channelUsers: c.channelUsers.map(cu => {
                if (cu.id === action.payload.idDemand) {
                  return action.data;
                }
                return cu;
              }),
            };
          }
          return c;
        }),
      };
    }

    case REQUEST_LIST_MESSAGES:
      if (!action.success) {
        return { ...state, messagesLoading: action.success === null };
      }

      return {
        ...state,
        messagesLoading: false,
        messages: action?.data["hydra:member"] || [],
      };

    case REQUEST_CREATE_MESSAGE:
      if (action.success === null) {
        return {
          ...state,
          messageSending: true,
        };
      }

      if (!action.success) {
        return {
          ...state,
          messageSending: false,
        };
      }

      let newMessage = action.data;
      messages = state.messages ? [...state.messages, newMessage] : [newMessage];
      return {
        ...state,
        messageSending: false,
        messages: messages,
      };

    case REQUEST_CONTACTS:
      if (action.success) {
        return { ...state, status: { ...state.status, getContacts: "success" }, contacts: action.data["hydra:member"] };
      }
      return { ...state, status: { ...state.status, getContacts: action.success === null ? "fetching" : "error" } };

    case ADD_CONTACT:
      if (action.success) {
        return {
          ...state,
          status: { ...state.status, addContact: "success" },
          contacts: [...state.contacts, action.data],
        };
      }
      return { ...state, status: { ...state.status, addContact: action.success === null ? "fetching" : "error" } };

    case UPDATE_CONTACT:
      if (action.success) {
        return {
          ...state,
          status: { ...state.status, updateContact: "success" },
          contacts: state.contacts.map(c => {
            if (c.id === action.data.id) {
              return {
                ...c,
                ...action.data,
              };
            }
            return c;
          }),
        };
      }
      return { ...state, status: { ...state.status, updateContact: action.success === null ? "fetching" : "error" } };

    case SET_CURRENT_CHAT:
      return {
        ...state,
        currentChatId: action.payload.id,
        currentChatIsChannel: action.payload.isChannel,
      };

    case CLEAR_MESSAGES:
      return {
        ...state,
        messages: [],
      };

    default:
      return state;
  }
};

export default chat;
