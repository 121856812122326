import { getCurrentFromState as getCurrentSpaceFromState } from "../space";

export const REQUEST_GET_COMPONENTS = "COMPONENT.REQUEST_GET_COMPONENTS";
export const REQUEST_REORDER_COMPONENTS = "COMPONENT.REQUEST_REORDER_COMPONENTS";
export const ADD_COMPONENT = "COMPONENT.ADD_COMPONENT";
export const UPDATE_COMPONENT = "COMPONENT.UPDATE_COMPONENT";
export const REMOVE_COMPONENT = "COMPONENT.REMOVE_COMPONENT";

export const getComponents = view => {
  return async (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    // use mock
    dispatch({
      type: REQUEST_GET_COMPONENTS,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/components/space/${currentSpace.id}?view=${view}`,
        method: "GET",
      },
    });
    // dispatch(setCurrentDashboard(components[0].id));
  };
};

export const addComponent = component => {
  return async (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    dispatch({
      type: ADD_COMPONENT,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/components/space/${currentSpace.id}`,
        method: "POST",
        data: component,
      },
    });
  };
};

export const updateComponent = component => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_COMPONENT,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/components/${component.id}`,
        method: "PUT",
        data: component,
      },
    });
  };
};

export const removeComponent = id => {
  return async (dispatch, getState) => {
    dispatch({
      type: REMOVE_COMPONENT,
      id,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/components/${id}`,
        method: "DELETE",
      },
    });
  };
};

export const reorderComponents = components => {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_REORDER_COMPONENTS,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/components/update`,
        method: "POST",
        data: components,
      },
    });
  };
};
