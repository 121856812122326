import React from "react";
import * as Icon from "react-feather";
const horizontalMenuConfig = [
  {
    type: "groupHeader",
    groupTitle: "My Space",
  },
  {
    id: "myspace",
    title: "My Space",
    type: "item",
    icon: <Icon.Airplay size={20} />,
    navLink: "/myspace",
  },
  {
    id: "explorer",
    title: "Explorer",
    type: "item",
    icon: <Icon.Mail size={20} />,
    navLink: "/explorer",
  },
  {
    id: "notification",
    title: "Notification",
    type: "item",
    icon: <Icon.MessageSquare size={20} />,
    navLink: "/notification",
  },
  {
    id: "feed",
    title: "Feed",
    type: "item",
    icon: <Icon.CheckSquare size={20} />,
    navLink: "/feed",
  },
  {
    id: "document",
    title: "Document",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    navLink: "/document",
  },
  {
    id: "action",
    title: "Action",
    type: "item",
    icon: <Icon.CheckSquare size={20} />,
    navLink: "/action",
  },
  {
    id: "chat",
    title: "Messages",
    type: "item",
    icon: <Icon.MessageSquare size={20} />,
    navLink: "/messages",
  },
  {
    id: "plus",
    title: "Plus",
    type: "item",
    icon: <Icon.CheckSquare size={20} />,
  },
];

export default horizontalMenuConfig;
