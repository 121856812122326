export const componentTitles = {
  Example1: "Utilisateurs",
  Example2: "Example 2",
  Example3: "Adhésion",
  Example4: "Activité",
  ArticlesAction: "Articles",
  Component1: "",
  Component2: "",
  Component3: "",
  Component4: "",
  Component5: "",
  Component6: "",
  Component7: "",
  Component8: "",
};

export const componentSizes = {
  Component7: "full",
  Component8: "full",
};

export const UPLOAD_MAX_SIZE = 3 * 1024 * 1024;

export const ARTICLE_STATUS_PUBLISHED = "published";
export const ARTICLE_STATUS_DRAFT = "draft";

export const CONTACT_STATUS_PENDING = "pending";
export const CONTACT_STATUS_ACCEPTED = "accepted";
export const CONTACT_STATUS_REJECTED = "rejected";
