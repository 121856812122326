import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import { APIMiddleware } from "./middlewares";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { createWhitelistFilter } from "redux-persist-transform-filter";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["auth", "customizer"],
  transforms: [
    createWhitelistFilter("space", ["dashboard", "space"]),
    createWhitelistFilter("tutorial", ["dashboard"]),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [thunk, APIMiddleware(), createDebounce()];

const initialState = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));

let persistor = persistStore(store);

export { store, history, persistor };
