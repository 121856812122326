import React from "react";
import { Badge, Navbar, NavItem, NavLink } from "reactstrap";
import { Plus, MessageSquare, CheckSquare } from "react-feather";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import NavbarBookmarks from "./NavbarBookmarks";
import NavbarUser from "./NavbarUser";
import { useTrad } from "../../../utility/traduction";
import { isAdmin, isMember } from "configs/acl";

const ThemeNavbar = props => {
  const history = useHistory();
  const t = useTrad("HibimSpaceAdmin_header_ThemeNavbar");

  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames("header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow", {
          "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
          "navbar-dark": colorsArr.includes(props.navbarColor),
          "bg-primary": props.navbarColor === "primary" && props.navbarType !== "static",
          "bg-danger": props.navbarColor === "danger" && props.navbarType !== "static",
          "bg-success": props.navbarColor === "success" && props.navbarType !== "static",
          "bg-info": props.navbarColor === "info" && props.navbarType !== "static",
          "bg-warning": props.navbarColor === "warning" && props.navbarType !== "static",
          "bg-dark": props.navbarColor === "dark" && props.navbarType !== "static",
          "d-none": props.navbarType === "hidden" && !props.horizontal,
          "floating-nav":
            (props.navbarType === "floating" && !props.horizontal) ||
            (!navbarTypes.includes(props.navbarType) && !props.horizontal),
          "navbar-static-top": props.navbarType === "static" && !props.horizontal,
          "fixed-top": props.navbarType === "sticky" || props.horizontal,
          scrolling: props.horizontal && props.scrolling,
        })}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div className="navbar-collapse d-flex align-items-center" id="navbar-mobile">
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              <div className="navbar-actions d-flex mr-auto align-items-center">
                <ul className="navbar-nav align-items-center">
                  <NavItem className="mobile-menu mr-auto" onClick={() => history.push("/messages")}>
                    <NavLink className="nav-menu-main menu-toggle hidden-xs is-active position-relative">
                      <MessageSquare className="ficon" />
                      {props?.currentUser?.chatUnreadCount > 0 && (
                        <Badge className="position-absolute" pill color="danger" style={{ top: 10, right: 0 }}>
                          {props.currentUser.chatUnreadCount}
                        </Badge>
                      )}
                    </NavLink>
                  </NavItem>
                  <NavItem className="mobile-menu mr-auto" onClick={() => history.push("/demandes")}>
                    <NavLink className="nav-menu-main menu-toggle hidden-xs is-active">
                      <CheckSquare className="ficon" />
                    </NavLink>
                  </NavItem>
                </ul>
                {(isMember(props.currentSpace) || isAdmin(props.currentSpace)) && (
                  <span
                    className="bg-primary rounded-circle p-50 ml-1 cursor-pointer"
                    onClick={() =>
                      history.push({
                        pathname: "/action",
                        state: { addAction: true },
                      })
                    }
                  >
                    <Plus className="text-white" />
                  </span>
                )}
              </div>
              {/*<div className="mr-1">
                <NavbarLangs />
              </div>*/}
              <a className="align-self-center pr-1" href={`${process.env.REACT_APP_ACCOUNTS_URL}/comment`}>
                {t("leftComment", "Laisser un commentaire")}
              </a>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Hibim</h2>
                </div>
              ) : null}

              <div className="justify-content-end">
                <NavbarUser handleAppOverlay={props.handleAppOverlay} changeCurrentLang={props.changeCurrentLang} />
              </div>
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default ThemeNavbar;
