import fp from "lodash/fp";
import { RESET_SPACE } from "redux/actions/space";

import {
  ADD_COMPONENT,
  UPDATE_COMPONENT,
  REMOVE_COMPONENT,
  REQUEST_GET_COMPONENTS,
} from "redux/actions/space/components";

const INITIAL_STATE = {
  components: null,
  current: null,
  status: {
    getComponents: null,
    add: null,
    update: null,
    remove: null,
  },
};

export default function dashboardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_GET_COMPONENTS: {
      if (action.success) {
        return { ...state, status: { ...state.status, getComponents: "success" }, components: action.data };
      }
      return { ...state, status: { ...state.status, getComponents: action.success === null ? "fetching" : "error" } };
    }
    case ADD_COMPONENT: {
      if (action.success) {
        return {
          ...state,
          status: { ...state.status, add: "success" },
          components: [action.data, ...state.components],
        };
      }
      return { ...state, status: { ...state.status, add: action.success === null ? "fetching" : "error" } };
    }
    case UPDATE_COMPONENT: {
      if (action.success) {
        const components = fp.map(comp => {
          if (comp.id === action.data.id) {
            return { ...comp, ...action.data };
          }
          return comp;
        })(state.components);
        return {
          ...state,
          status: { ...state.status, update: "success" },
          components,
        };
      }
      return { ...state, status: { ...state.status, update: action.success === null ? "fetching" : "error" } };
    }
    case REMOVE_COMPONENT: {
      if (action.success) {
        return {
          ...state,
          status: { ...state.status, remove: "success" },
          components: state.components.filter(comp => comp.id !== action.id),
        };
      }
      return { ...state, status: { ...state.status, remove: action.success === null ? "fetching" : "error" } };
    }
    case RESET_SPACE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
