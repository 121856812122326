import { RESET_SPACE } from "..";
import { fetchComponents } from "../../../../utility";
import axios from "../../../../utility/api";

export const PUSH_SELECTED_ACTION = "ACTIONS.PUSH_SELECTED_ACTION";
export const REMOVE_SELECTED_ACTION = "ACTIONS.REMOVE_SELECTED_ACTION";
export const UPDATE_SELECTED_ACTION_TAB = "ACTIONS.UPDATE_SELECTED_ACTION_TAB";
export const GET_FROM_BOOKMARKS = "ACTIONS.GET_FROM_BOOKMARKS";
export const PUSH_TO_BOOKMARKS = "ACTIONS.PUSH_TO_BOOKMARKS";
export const SET_ACTIONS = "SET_ACTIONS";
export const UPDATE_SELECTED_ACTIONS = "UPDATE_SELECTED_ACTIONS";

export function pushSelectedAction(selectedAction) {
  return async (dispatch, getState) => {
    try {
      let currentSpace = getState().space.space.current;
      if (!currentSpace) {
        return null;
      }
      let spaceId = parseInt(currentSpace["@id"].match(/\d+/g)[0]);

      let { name, title } = selectedAction;
      let order = 0;
      let view = "action";

      let newAction = await axios.post("/api/components/space/" + spaceId, { name, view, order }).then(res => res.data);

      let { id } = newAction;

      dispatch({
        type: PUSH_SELECTED_ACTION,
        selectedAction: { name, title, order, view, id },
      });
    } catch (e) {
      console.log("error adding action", e);
    }
  };
}

export function removeSelectedAction(selectedAction) {
  return async (dispatch, getState) => {
    try {
      let componentId = selectedAction.id;

      dispatch({
        type: REMOVE_SELECTED_ACTION,
        payload: selectedAction,
      });

      if (componentId) {
        await axios.delete("/api/components/" + componentId);
      }
    } catch (e) {
      console.log("error removing action", e);
    }
  };
}

export function updateSelectedActionsTab(selectedActions) {
  return {
    type: UPDATE_SELECTED_ACTION_TAB,
    selectedActions,
  };
}

export function getFromBookmarks(selectedAction) {
  return {
    type: GET_FROM_BOOKMARKS,
    selectedAction,
  };
}

export function pushToBookmarks(selectedAction) {
  return {
    type: PUSH_TO_BOOKMARKS,
    selectedAction,
  };
}

export function getSelectedActionsFromState(state) {
  if (state && state.space && state.space.action && state.space.action.selectedActions) {
    return state.space.action.selectedActions;
  }
  return [];
}

export function getBookmarksFromState(state) {
  if (state && state.space && state.space.action) {
    return state.space.action.bookmarks || [];
  }
  return [];
}

export function fetchActions(view = "action") {
  return async (dispatch, getState) => {
    try {
      let currentSpace = getState().space.space.current;
      let spaceId = parseInt(currentSpace["@id"].match(/\d+/g)[0]);
      dispatch({ type: RESET_SPACE });
      let components = await fetchComponents({ spaceId, view: "action" });

      if (components) {
        dispatch({ type: SET_ACTIONS, payload: components });
      }
    } catch (e) {
      console.log("error fetching actions", e);
    }
  };
}

export const orderActions = components => async (dispatch, getState) => {
  try {
    let data = components.map(({ id, order }) => {
      return { id, order };
    });

    console.log("orderActions data :", data);
    dispatch({ type: SET_ACTIONS, payload: components });
    await axios.post("/api/components/update", data);
  } catch (e) {
    console.log("error ordering actions", e);
  }
};
