import Cookies from "js-cookie";

export const REQUEST_AUTH = "AUTH.REQUEST_AUTH";
export const SUCCESS_AUTH = "AUTH.SUCCESS_AUTH";
export const ERROR_AUTH = "AUTH.ERROR_AUTH";
export const CLEAR = "AUTH.CLEAR";
export const GET_CURRENT_USER = "AUTH.GET_CURRENT_USER";
export const UPDATE_CURRENT_USER = "AUTH.UPDATE_CURRENT_USER";

function requestAuth() {
  return {
    type: REQUEST_AUTH,
    auth: {
      pending: true,
      isLogged: false,
    },
  };
}

function successAuth(token, email) {
  return {
    type: SUCCESS_AUTH,
    auth: {
      token,
      email,
      isLogged: true,
      pending: false,
    },
  };
}

function errorAuth() {
  return {
    type: ERROR_AUTH,
    auth: {
      pending: false,
      isLogged: false,
    },
  };
}

function clear() {
  return {
    type: CLEAR,
    auth: {
      pending: undefined,
      isLogged: undefined,
      token: undefined,
    },
  };
}

export function getCurrentUser(email) {
  return (dispatch, getState) => {
    if (!isLogged(getState())) {
      return;
    }
    return dispatch({
      type: GET_CURRENT_USER,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/user/me`,
        method: "GET",
      },
    });
  };
}

export function authenticate() {
  return dispatch => {
    dispatch(requestAuth());
    const token = Cookies.get("token") 
    const email = Cookies.get("email")
    if (token && email) {
      dispatch(successAuth(token, email));
      dispatch(getCurrentUser(email));
    } else {
      dispatch(errorAuth());
    }
  };
}

export function logout() {
  return dispatch => {
    dispatch(clear());
    window.location.replace(`${process.env.REACT_APP_ACCOUNTS_URL}/logout`);
  };
}

export function updateCurrentUser(user) {
  return {
    type: UPDATE_CURRENT_USER,
    user,
  };
}

export function getBearerTokenFromState(state) {
  if (state.auth && state.auth.token) {
    return state.auth.token;
  }
  return null;
}

export function authPending(state) {
  if (state.auth) {
    return state.auth.pending;
  }
  return undefined;
}

export function isLogged(state) {
  if (state.auth) {
    return state.auth.isLogged;
  }
  return undefined;
}

export function isUserLoading(state) {
  if (state.auth) {
    return Boolean(state.auth.userLoading);
  }
  return false;
}

export function getCurrentUserFromState(state) {
  if (state.auth) {
    return state.auth.user || null;
  }
  return null;
}
