import { getCurrentFromState as getCurrentSpaceFromState } from "../space";

export const REQUEST_LIST = "ARTICLES.REQUEST_LIST";
export const REQUEST_CREATE = "ARTICLES.REQUEST_CREATE";
export const REQUEST_UPDATE = "ARTICLES.REQUEST_UPDATE";
export const REQUEST_DELETE = "ARTICLES.REQUEST_DELETE";
export const RESET_UPSERT = "ARTICLES.RESET_UPSERT";
export const FILTER_LIST = "ARTICLES.FILTER_LIST";
export const REQUEST_ARTICLES_CATEGORIES_LIST = "ARTICLES.REQUEST_ARTICLES_CATEGORIES_LIST";
export const REQUEST_ARTICLES_CATEGORIES_ADD = "ARTICLES.REQUEST_ARTICLES_CATEGORIES_ADD";

export function getArticles() {
  return (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    dispatch({
      type: REQUEST_LIST,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/spaces/${currentSpace.id}/articles`,
        method: "GET",
      },
    });
    dispatch(filterList("all"));
  };
}

export function requestCreate(data) {
  return (dispatch, getState) => {
    const state = getState();
    const currentSpace = getCurrentSpaceFromState(state);
    if (!currentSpace) {
      return null;
    }

    dispatch({
      type: REQUEST_CREATE,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/articles`,
        method: "POST",
        data: {
          ...data,
          space: currentSpace["@id"],
        },
      },
    });
    dispatch(filterList("all"));
  };
}

export function getArticlesCategories() {
  return (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    dispatch({
      type: REQUEST_ARTICLES_CATEGORIES_LIST,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/spaces/${currentSpace.id}/articles_categories`,
        method: "GET",
      },
    });
  };
}

export function addArticlesCategory(categoryLabel) {
  return (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    dispatch({
      type: REQUEST_ARTICLES_CATEGORIES_ADD,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/articles_categories`,
        method: "POST",
        data: {
          space: currentSpace["@id"],
          label: categoryLabel,
        },
      },
    });
  };
}

export function requestUpdate(id, data) {
  return {
    type: REQUEST_UPDATE,
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/articles/${id}`,
      method: "PUT",
      data,
    },
  };
}

export function requestDelete(id) {
  return {
    type: REQUEST_DELETE,
    id,
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/articles/${id}`,
      method: "DELETE",
    },
  };
}

export function resetUpsert() {
  return {
    type: RESET_UPSERT,
  };
}

export function filterList(filter) {
  return {
    type: FILTER_LIST,
    filter,
  };
}

export function getArticlesFromState(state) {
  if (state.space && state.space.articles) {
    return state.space.articles.articles || [];
  }
  return [];
}

export function getListPendingFromState(state) {
  if (state.space && state.space.articles) {
    return state.space.articles.listPending || false;
  }
  return false;
}

export function getFilterTypeFromState(state) {
  if (state.space && state.space.articles) {
    return state.space.articles.filter || null;
  }
  return null;
}
