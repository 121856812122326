import React, { useEffect, useCallback } from "react";
import Router from "./Router";
import "./components/@vuexy/rippleButton/RippleButton";

import "react-perfect-scrollbar/dist/css/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import { useSelector, useDispatch } from "react-redux";
import { authPending, isLogged, authenticate } from "./redux/actions/auth";
import { requestList, getCurrentFromState, requestMembers } from "./redux/actions/space/space";
import { getPages } from "./redux/actions/space/pages";
import { fetchThemeConfig } from "./redux/actions/customizer";
import { getModalsFromUser } from "./redux/actions/tutorial/modal";
import { changeLang } from "./redux/actions/traduction";
import { acceptedLocales } from "./configs/traduction";
import { fetchAllUsers } from "./redux/actions/user";
import { getFormData } from "redux/actions/space/formdata";

const App = props => {
  const { pending, logged, auth, currentSpace } = useSelector(state => ({
    pending: authPending(state),
    logged: isLogged(state),
    auth: state.auth,
    currentSpace: getCurrentFromState(state),
  }));

  const userId = auth.user?.id;
  const spaceParam = new URLSearchParams(window?.location?.search).get("space");
  const dispatch = useDispatch();
  const handleAuthentication = useCallback(() => dispatch(authenticate()), [dispatch]);
  const handleList = useCallback(() => dispatch(requestList(spaceParam)), [dispatch, spaceParam]);

  useEffect(() => {
    if (pending === undefined && logged === undefined) {
      handleAuthentication();
    }
    if (pending === false && logged === false) {
      if (process.env.REACT_APP_ACCOUNTS_URL) {
        window.location.replace(
          `${process.env.REACT_APP_ACCOUNTS_URL}?redirect=${encodeURIComponent(window.location.href)}`,
        );
      }
    }
    if (logged === true && userId) {
      handleList();
    }
  }, [pending, logged, handleAuthentication, handleList, userId]);

  useEffect(() => {
    // fetch stheme config
    if (logged && userId) {
      console.log("use effect : fetch spaces & theme config");
      // dispatch(fetchSpaces());
      dispatch(fetchThemeConfig());
    }
  }, [dispatch, logged, userId]);

  /** Get tutorial modals visibility */
  useEffect(() => {
    if (userId) {
      dispatch(getModalsFromUser());
    }
  }, [userId, dispatch]);

  // load lang
  useEffect(() => {
    if (auth?.user?.languages) {
      let locale = auth.user.languages[0];
      if (locale && acceptedLocales.includes(locale)) {
        dispatch(changeLang(locale));
      }
    }
  }, [auth.user, dispatch]);

  // get current space data : pages, members...
  useEffect(() => {
    if (currentSpace?.id) {
      // get space pages
      dispatch(getPages());
      // get forms data
      dispatch(getFormData());
      // get members
      dispatch(requestMembers());
    }
  }, [currentSpace]);

  // fetch all users*
  useEffect(() => {
    if (auth.user) {
      dispatch(fetchAllUsers());
    }
  }, [auth.user]);

  return <Router />;
};

export default App;
