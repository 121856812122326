import { getCurrentFromState as getCurrentSpaceFromState } from "../space";

export const REQUEST_LIST = "PORTFOLIO.REQUEST_LIST";
export const REQUEST_CREATE = "PORTFOLIO.REQUEST_CREATE";
export const REQUEST_UPDATE = "PORTFOLIO.REQUEST_UPDATE";
export const REQUEST_DELETE = "PORTFOLIO.REQUEST_DELETE";
export const RESET_UPSERT = "PORTFOLIO.RESET_UPSERT";

export function getPortfolios() {
  return (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    dispatch({
      type: REQUEST_LIST,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/spaces/${currentSpace.id}/portfolios`,
        method: "GET",
      },
    });
  };
}

export function requestCreate(data) {
  return (dispatch, getState) => {
    const state = getState();
    const currentSpace = getCurrentSpaceFromState(state);
    if (!currentSpace) {
      return null;
    }

    dispatch({
      type: REQUEST_CREATE,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/portfolios`,
        method: "POST",
        data: {
          ...data,
          space: currentSpace["@id"],
        },
      },
    });
  };
}

export function requestUpdate(id, data) {
  return {
    type: REQUEST_UPDATE,
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/portfolios/${id}`,
      method: "PUT",
      data,
    },
  };
}

export function requestDelete(id) {
  return {
    type: REQUEST_DELETE,
    id,
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/portfolios/${id}`,
      method: "DELETE",
    },
  };
}

export function resetUpsert() {
  return {
    type: RESET_UPSERT,
  };
}

export function getPortfoliosFromState(state) {
  if (state.space && state.space.portfolios) {
    return state.space.portfolios.portfolios || [];
  }
  return [];
}

export function getListPendingFromState(state) {
  if (state.space && state.space.portfolios) {
    return state.space.portfolios.listPending || false;
  }
  return false;
}

export function getFilterTypeFromState(state) {
  if (state.space && state.space.portfolios) {
    return state.space.portfolios.filter || null;
  }
  return null;
}
