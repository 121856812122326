import axios from "../../../../utility/api";

export const REQUEST_LIST = "SPACES.REQUEST_LIST";
export const REQUEST_CURRENT = "SPACES.REQUEST_CURRENT";
export const REQUEST_DELETE = "SPACES.REQUEST_DELETE";
export const REQUEST_MEMBERS = "SPACES.REQUEST_MEMBERS";
export const UPDATE_MEMBER = "SPACES.UPDATE_MEMBER";
export const REQUEST_SEARCH_TERM = "SPACES.REQUEST_SEARCH_TERM";
export const REQUEST_UPDATE_CONTENT_ROLE = "SPACES.REQUEST_UPDATE_CONTENT_ROLE";
export const CLEAR = "SPACES.CLEAR";
export const UPDATE_CURRENT_SPACE = "SPACES.UPDATE_CURRENT_SPACE";

export const requestList = space => (dispatch, getState) => {
  const userId = getState().auth.user.id;
  dispatch({
    type: REQUEST_LIST,
    space,
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/space?user=${userId}`,
      method: "GET",
    },
  });
};

export function requestCurrent(id) {
  return {
    type: REQUEST_CURRENT,
    payload: {
      id,
    },
    request: {
      url: `${process.env.REACT_APP_API_URL}${id}`,
      method: "GET",
    },
  };
}

export function requestDelete(id) {
  return {
    type: REQUEST_DELETE,
    payload: {
      id,
    },
    request: {
      url: `${process.env.REACT_APP_API_URL}${id}`,
      method: "DELETE",
    },
  };
}

export function requestMembers() {
  return (dispatch, getState) => {
    const state = getState();
    const currentSpace = getCurrentFromState(state);
    if (!currentSpace) {
      return null;
    }

    const currentSpaceId = currentSpace["@id"].replace("/api/space/", "");
    dispatch({
      type: REQUEST_MEMBERS,
      request: {
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/member/get-members-space/${currentSpaceId}`,
      },
    });
  };
}

export function updateMember(member) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_MEMBER,
      member,
      request: {
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/api/space/member/${member.id}`,
        data: member,
      },
    });
  };
}

export function requetsSearchTerm(term) {
  return (dispatch, getState) => {
    const state = getState();
    const currentSpace = getCurrentFromState(state);
    if (!currentSpace) {
      return null;
    }
    dispatch({
      type: REQUEST_SEARCH_TERM,
      request: {
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/spaces/${currentSpace.id}/search?term=${term}`,
      },
    });
  };
}

export function requestUpdateContent(contentRole, newContentRoles, deletedContentRole) {
  return (dispatch, getState) => {
    for (const spaceMember of newContentRoles) {
      dispatch({
        type: REQUEST_UPDATE_CONTENT_ROLE,
        request: {
          method: "POST",
          updateType: "ADD",
          url: `${process.env.REACT_APP_API_URL}/api/content_roles`,
          data: {
            ...contentRole,
            spaceMember,
          },
        },
      });
    }
    for (const id of deletedContentRole) {
      dispatch({
        type: REQUEST_UPDATE_CONTENT_ROLE,
        updateType: "REMOVE",
        id,
        request: {
          method: "DELETE",
          url: `${process.env.REACT_APP_API_URL}/api/content_roles/${id}`,
        },
      });
    }
  };
}

export function reset() {
  return {
    type: CLEAR,
  };
}

export function getListFromState(state) {
  const { space } = state;
  if (space && space.space) {
    return space.space.list || [];
  }
  return [];
}

export function getCurrentFromState(state) {
  const { space } = state;
  if (space && space.space) {
    return space.space.current || null;
  }
  return null;
}

export function getLoadingFromState(state) {
  const { space } = state;
  if (space && space.space) {
    return space.space.loading || false;
  }
  return false;
}

export function getCurrentLoadingFromState(state) {
  const { space } = state;
  if (space && space.space) {
    return space.space.currentLoading || false;
  }
  return false;
}

export function getDeletingFromState(state) {
  const { space } = state;
  if (space && space.space) {
    return space.space.deleting || false;
  }
  return false;
}

export function getMembersFromState(state) {
  const { space } = state;
  if (space && space.space) {
    return space.space.members || [];
  }
  return [];
}

export function getSearhResultFromState(state) {
  return state.space?.space?.searchResults;
}

export function getSearchLoadingStateFromState(state) {
  return state.space?.space?.searching;
}

export const updateCurrentSpace = data => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_CURRENT_SPACE, status: "fetching" });
    let currentSpace = getState().space.space.current;
    let formData = Object.keys(data).reduce((acc, key) => {
      if (data[key]) {
        acc[key] = data[key];
      }
      return acc;
    }, {}); // only non falsy values
    console.log("updateCurrentSpace data", formData);
    await axios.put("/api/space/" + currentSpace.id, formData);
    dispatch({ type: UPDATE_CURRENT_SPACE, data, status: "success" });
    return { status: "success" };
  } catch (e) {
    console.log("error updateCurrentSpace", e);
    dispatch({ type: UPDATE_CURRENT_SPACE, error: e, status: "error" });
    return { status: "error" };
  }
};

export const updateOpeningTimes = openingTimes => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_CURRENT_SPACE, status: "fetching" });
    console.log("#1", openingTimes)
    let currentSpace = getState()?.space?.space?.current;
    // update existing opening_time ressources
    let existingOpeningTimes = openingTimes.filter(e => e.id);
    console.log("#2", existingOpeningTimes)
    for (var i = 0; i < existingOpeningTimes.length; i++) {
      let time = existingOpeningTimes[i];
      let res = await axios.put("/api/opening_times/" + time.id, time).then(res => res.data);
      console.log("#2 res", openingTimes)
    }

    let times = existingOpeningTimes; // for redux sync
    // create opening_time ressource if needed
    let newOpeningTimes = openingTimes.filter(e => !e.id);
    console.log("#3", newOpeningTimes)
    for (var i = 0; i < newOpeningTimes.length; i++) {
      let time = newOpeningTimes[i];
      let res = await axios
        .post("/api/opening_times", { ...time, space: "/api/space/" + currentSpace.id })
        .then(res => res.data);
      console.log("#3 res", openingTimes)
      times.push({...time, ...res});
    }

    let data = {openingTimes: times}
    console.log("#4 data", data)
    dispatch({ type: UPDATE_CURRENT_SPACE, data, status: "success" });
    return { status: "success" };
  } catch (e) {
    console.log(e)
    dispatch({ type: UPDATE_CURRENT_SPACE, error: e, status: "error" });
    return { status: "error" };
  }
};
