import { REQUEST_LIST, REQUEST_CREATE, REQUEST_UPDATE, FILTER_LIST } from "../../../actions/space/demands";

const demands = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      if (action.success === null) {
        return {
          ...state,
          listPending: true,
        };
      }

      if (!action.success) {
        return {
          ...state,
          listPending: false,
        };
      }

      let demands = action.data && action.data["hydra:member"] ? action.data["hydra:member"] : [];
      return {
        ...state,
        listPending: false,
        allDemands: demands || [],
        filter: "all",
        demands: demands.filter(demand => demand.isDeleted === false),
      };

    case REQUEST_CREATE:
      if (action.success === null) {
        return {
          ...state,
          createPending: true,
        };
      }

      if (!action.success) {
        return {
          ...state,
          createPending: false,
        };
      }

      const newAllDemands = state.allDemands ? [...state.allDemands, action.data] : [action.data];
      return {
        ...state,
        createPending: false,
        allDemands: newAllDemands,
        filter: "all",
        demands: newAllDemands.filter(demand => demand.isDeleted === false),
      };

    case REQUEST_UPDATE:
      if (action.success === null) {
        return {
          ...state,
          createPending: true,
        };
      }

      if (!action.success) {
        return {
          ...state,
          createPending: false,
        };
      }

      const instance = action.data || {};
      let d = state.allDemands || [];
      const instanceIndex = d.findIndex(val => val.id === instance.id);
      if (instanceIndex !== -1) {
        d = d.slice();
        d.splice(instanceIndex, 1, instance);
      }

      state = {
        ...state,
        createPending: false,
        allDemands: d,
      };
      action.type = FILTER_LIST;
      action.filter = state.filter || "all";

    // eslint-disable-next-line no-fallthrough
    case FILTER_LIST:
      if (!state.allDemands) {
        return {
          ...state,
          filter: "all",
          demands: [],
        };
      }
      if (action.filter.includes("label=")) {
        const labels = action.filter.split("=")[1].split(";");
        return {
          ...state,
          filter: "label",
          demands: state.allDemands.filter(
            demand =>
              demand.Labels && demand.Labels.length && demand.Labels.some(label => labels.indexOf(label) !== -1),
          ),
        };
      }

      switch (action.filter) {
        case "all":
          return {
            ...state,
            filter: "all",
            demands: state.allDemands.filter(demand => demand.isDeleted === false),
          };

        case "favorite":
          return {
            ...state,
            filter: "favorite",
            demands: state.allDemands.filter(demand => demand.isFavorite === true),
          };

        case "deleted":
          return {
            ...state,
            filter: "deleted",
            demands: state.allDemands.filter(demand => demand.isDeleted === true),
          };

        case "complete":
          return {
            ...state,
            filter: "complete",
            demands: state.allDemands.filter(demand => demand.isDone === true),
          };

        default:
          return state;
      }
    default:
      return state;
  }
};

export default demands;
