import { getCurrentFromState as getCurrentSpaceFromState } from "../space";
import uniqueSlug from "unique-slug";
import { TEMPLATE_CUSTOM_PAGE, TEMPLATE_CUSTOM_FORM } from "configs/elements";

export const REQUEST_GET_TEMPLATE = "PAGES.REQUEST_GET_TEMPLATE";
export const REQUEST_GET_TEMPLATES = "PAGES.REQUEST_GET_TEMPLATES";
export const REQUEST_GET_PAGES = "PAGES.REQUEST_GET_PAGES";
export const REQUEST_GET_PAGE = "PAGES.REQUEST_GET_PAGE";
export const REQUEST_ADD_PAGE = "PAGES.REQUEST_ADD_PAGE";
export const REQUEST_UPDATE_PAGE = "PAGES.REQUEST_UPDATE_PAGE";
export const REQUEST_REMOVE_PAGE = "PAGES.REQUEST_REMOVE_PAGE";
export const REQUEST_GET_PAGE_ELEMENTS = "PAGES.REQUEST_GET_PAGE_ELEMENTS";
export const REQUEST_ADD_PAGE_ELEMENT = "PAGES.REQUEST_ADD_PAGE_ELEMENT";
export const REQUEST_UPDATE_PAGE_ELEMENT = "PAGES.REQUEST_UPDATE_PAGE_ELEMENT";
export const REQUEST_REORDER_PAGE_ELEMENTS = "PAGES.REQUEST_REORDER_PAGE_ELEMENTS";
export const REQUEST_REMOVE_PAGE_ELEMENT = "PAGES.REQUEST_REMOVE_PAGE_ELEMENT";

export function getPages() {
  return (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    dispatch({
      type: REQUEST_GET_PAGES,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/spaces/${currentSpace.id}/pages`,
        method: "GET",
      },
    });
  };
}

export function getTemplates() {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_GET_TEMPLATES,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/pages/templates`,
        method: "GET",
      },
    });
  };
}

export function getTemplate(id) {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_GET_TEMPLATE,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/pages/templates/${id}`,
        method: "GET",
      },
    });
  };
}

export function getPage(id) {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_GET_PAGE,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/pages/${id}`,
        method: "GET",
      },
    });
  };
}

export function addPageFromTemplate(template) {
  return (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    const slug = [TEMPLATE_CUSTOM_PAGE, TEMPLATE_CUSTOM_FORM].includes(template.template)
      ? `${template.type}-${uniqueSlug()}`
      : undefined;
    dispatch({
      type: REQUEST_ADD_PAGE,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/spaces/${currentSpace.id}/pages/add_from_template`,
        method: "POST",
        data: {
          template: template.template,
          slug,
        },
      },
    });
  };
}

export function updatePage(id, page) {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_UPDATE_PAGE,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/pages/${id}`,
        method: "PUT",
        data: page,
      },
    });
  };
}

export function removePage(id) {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_REMOVE_PAGE,
      id,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/pages/${id}`,
        method: "DELETE",
      },
    });
  };
}

export function getPageElements(id) {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_GET_PAGE_ELEMENTS,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/pages/${id}/elements`,
        method: "GET",
      },
    });
  };
}

export function addPageElement(element) {
  return (dispatch, getState) => {
    let options = {};
    if (typeof element.options === "string") {
      options = JSON.parse(element.options);
    }
    dispatch({
      type: REQUEST_ADD_PAGE_ELEMENT,
      element,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/page_elements`,
        data: { ...element, id: undefined, "@id": undefined, "@type": undefined, options },
        method: "POST",
      },
    });
  };
}

export function updatePageElement(id, element) {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_UPDATE_PAGE_ELEMENT,
      id,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/page_elements/${id}`,
        method: "PUT",
        data: element,
      },
    });
  };
}

export function reorderPageElements(elements) {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_REORDER_PAGE_ELEMENTS,
      elements,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/page_elements/reorder`,
        method: "POST",
        data: elements,
      },
    });
  };
}

export function removePageElement(id) {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_REMOVE_PAGE_ELEMENT,
      id,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/page_elements/${id}`,
        method: "DELETE",
      },
    });
  };
}
