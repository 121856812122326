import { RESET_SPACE } from "redux/actions/space";
import {
  REQUEST_LIST,
  REQUEST_CREATE,
  REQUEST_UPDATE,
  REQUEST_DELETE,
  RESET_UPSERT,
} from "../../../actions/space/portfolio";

const INITIAL_STATE = {
  allPortfolios: null,
  current: null,
  status: {
    getPortfolios: null,
    addPortfolio: null,
    updatePortfolio: null,
    deletePortfolio: null,
  },
};

const portfolio = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      if (!action.success) {
        return {
          ...state,
          status: { ...state.status, getPortfolios: action.success === null ? "fetching" : "error" },
        };
      }

      return {
        ...state,
        status: { ...state.status, getPortfolios: "success" },
        allPortfolios: action?.data?.["hydra:member"] || [],
      };

    case REQUEST_CREATE:
      if (!action.success) {
        return {
          ...state,
          status: { ...state.status, addPortfolio: action.success === null ? "fetching" : "error" },
        };
      }

      return {
        ...state,
        status: { ...state.status, addPortfolio: "success" },
        allPortfolios: state.allPortfolios ? [...state.allPortfolios, action.data] : [action.data],
      };

    case REQUEST_DELETE:
      if (!action.success) {
        return {
          ...state,
          status: { ...state.status, deletePortfolio: action.success === null ? "fetching" : "error" },
        };
      }

      return {
        ...state,
        status: { ...state.status, deletePortfolio: "success" },
        allPortfolios: state.allPortfolios.filter(a => a.id !== action.id),
      };

    case REQUEST_UPDATE:
      if (!action.success) {
        return {
          ...state,
          status: { ...state.status, updatePortfolio: action.success === null ? "fetching" : "error" },
        };
      }

      const instance = action.data || {};
      let d = state.allPortfolios || [];
      const instanceIndex = d.findIndex(val => val.id === instance.id);
      if (instanceIndex !== -1) {
        d = d.slice();
        d.splice(instanceIndex, 1, instance);
      }

      return {
        ...state,
        status: { ...state.status, updatePortfolio: "success" },
        allPortfolios: d,
      };

    case RESET_UPSERT: {
      return {
        ...state,
        status: { ...state.status, updatePortfolio: null, addCategory: null },
      };
    }

    case RESET_SPACE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default portfolio;
