import { getCurrentFromState as getCurrentSpaceFromState } from "../space";

export const REQUEST_LIST = "ACTIVITIES.REQUEST_LIST";
export const REQUEST_CREATE = "ACTIVITIES.REQUEST_CREATE";
export const REQUEST_UPDATE = "ACTIVITIES.REQUEST_UPDATE";
export const REQUEST_DELETE = "ACTIVITIES.REQUEST_DELETE";
export const RESET_UPSERT = "ACTIVITIES.RESET_UPSERT";
export const FILTER_LIST = "ACTIVITIES.FILTER_LIST";
export const REQUEST_ACTIVITIES_CATEGORIES_LIST = "ACTIVITIES.REQUEST_ACTIVITIES_CATEGORIES_LIST";
export const REQUEST_ACTIVITIES_CATEGORIES_ADD = "ACTIVITIES.REQUEST_ACTIVITIES_CATEGORIES_ADD";

export function getActivities() {
  return (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    dispatch({
      type: REQUEST_LIST,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/spaces/${currentSpace.id}/activities`,
        method: "GET",
      },
    });
    dispatch(filterList("all"));
  };
}

export function requestCreate(data) {
  return (dispatch, getState) => {
    const state = getState();
    const currentSpace = getCurrentSpaceFromState(state);
    if (!currentSpace) {
      return null;
    }

    dispatch({
      type: REQUEST_CREATE,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/activity`,
        method: "POST",
        data: {
          ...data,
          space: currentSpace["@id"],
        },
      },
    });
    dispatch(filterList("all"));
  };
}

export function getActivitiesCategories() {
  return (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    dispatch({
      type: REQUEST_ACTIVITIES_CATEGORIES_LIST,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/spaces/${currentSpace.id}/activities_categories`,
        method: "GET",
      },
    });
  };
}

export function addActivitiesCategory(categoryLabel) {
  return (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    dispatch({
      type: REQUEST_ACTIVITIES_CATEGORIES_ADD,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/activitycategory`,
        method: "POST",
        data: {
          space: currentSpace["@id"],
          label: categoryLabel,
        },
      },
    });
  };
}

export function requestUpdate(id, data) {
  return {
    type: REQUEST_UPDATE,
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/activity/${id}`,
      method: "PUT",
      data,
    },
  };
}

export function requestDelete(id) {
  return {
    type: REQUEST_DELETE,
    id,
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/activity/${id}`,
      method: "DELETE",
    },
  };
}

export function resetUpsert() {
  return {
    type: RESET_UPSERT,
  };
}

export function filterList(filter) {
  return {
    type: FILTER_LIST,
    filter,
  };
}

export function getActivitiesFromState(state) {
  if (state.space && state.space.activities) {
    return state.space.activities.activities || [];
  }
  return [];
}

export function getListPendingFromState(state) {
  if (state.space && state.space.activities) {
    return state.space.activities.listPending || false;
  }
  return false;
}

export function getFilterTypeFromState(state) {
  if (state.space && state.space.activities) {
    return state.space.activities.filter || null;
  }
  return null;
}
