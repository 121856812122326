import { RESET_SPACE } from "redux/actions/space";
import {
  REQUEST_LIST,
  REQUEST_CREATE,
  REQUEST_UPDATE,
  REQUEST_DELETE,
  FILTER_LIST,
  REQUEST_ARTICLES_CATEGORIES_LIST,
  REQUEST_ARTICLES_CATEGORIES_ADD,
  RESET_UPSERT,
} from "../../../actions/space/articles";

const INITIAL_STATE = {
  articles: null,
  allArticles: null,
  current: null,
  filter: "all",
  categories: null,
  status: {
    getArticles: null,
    addArticle: null,
    updateArticle: null,
    deleteArticle: null,
    getCategories: null,
    addCategory: null,
  },
};

const articles = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      if (!action.success) {
        return {
          ...state,
          status: { ...state.status, getArticles: action.success === null ? "fetching" : "error" },
        };
      }

      let allArticles = action?.data?.["hydra:member"] || [];
      return {
        ...state,
        status: { ...state.status, getArticles: "success" },
        allArticles,
        filter: "all",
        articles: allArticles.filter(article => !article.isDeleted),
      };

    case REQUEST_CREATE:
      if (!action.success) {
        return {
          ...state,
          status: { ...state.status, addArticle: action.success === null ? "fetching" : "error" },
        };
      }

      const newAllArticles = state.allArticles ? [...state.allArticles, action.data] : [action.data];
      return {
        ...state,
        status: { ...state.status, addArticle: "success" },
        allArticles: newAllArticles,
        filter: "all",
        articles: newAllArticles.filter(article => !article.isDeleted),
      };

    case REQUEST_DELETE:
      if (!action.success) {
        return {
          ...state,
          status: { ...state.status, deleteArticle: action.success === null ? "fetching" : "error" },
        };
      }

      return {
        ...state,
        status: { ...state.status, deleteArticle: "success" },
        articles: state.articles.filter(a => a.id !== action.id),
      };

    case REQUEST_UPDATE:
      if (!action.success) {
        return {
          ...state,
          status: { ...state.status, updateArticle: action.success === null ? "fetching" : "error" },
        };
      }

      const instance = action.data || {};
      let d = state.allArticles || [];
      const instanceIndex = d.findIndex(val => val.id === instance.id);
      if (instanceIndex !== -1) {
        d = d.slice();
        d.splice(instanceIndex, 1, instance);
      }

      state = {
        ...state,
        status: { ...state.status, updateArticle: "success" },
        allArticles: d,
      };
      action.type = FILTER_LIST;
      action.filter = state.filter || "all";

    // eslint-disable-next-line no-fallthrough
    case FILTER_LIST:
      if (!state.allArticles) {
        return {
          ...state,
          filter: "all",
          articles: [],
        };
      }
      if (action.filter.includes("label=")) {
        const labels = action.filter.split("=")[1].split(";");
        return {
          ...state,
          filter: "label",
          articles: state.allArticles.filter(
            article =>
              article.Labels && article.Labels.length && article.Labels.some(label => labels.indexOf(label) !== -1),
          ),
        };
      }

      switch (action.filter) {
        case "all":
          return {
            ...state,
            filter: "all",
            articles: state.allArticles.filter(article => !article.isDeleted),
          };

        case "favorite":
          return {
            ...state,
            filter: "favorite",
            articles: state.allArticles.filter(article => article.isFavorite === true),
          };

        case "deleted":
          return {
            ...state,
            filter: "deleted",
            articles: state.allArticles.filter(article => article.isDeleted === true),
          };

        case "complete":
          return {
            ...state,
            filter: "complete",
            articles: state.allArticles.filter(article => article.isDone === true),
          };

        default:
          return state;
      }

    case REQUEST_ARTICLES_CATEGORIES_LIST:
      if (action.success) {
        return {
          ...state,
          status: { ...state.status, getCategories: "success" },
          categories: action.data["hydra:member"],
        };
      }
      return { ...state, status: { ...state.status, getCategories: action.success === null ? "fetching" : "error" } };

    case REQUEST_ARTICLES_CATEGORIES_ADD: {
      if (action.success) {
        return {
          ...state,
          status: { ...state.status, addCategory: "success" },
          categories: [...state.categories, action.data],
        };
      }
      return { ...state, status: { ...state.status, addCategory: action.success === null ? "fetching" : "error" } };
    }

    case RESET_UPSERT: {
      return {
        ...state,
        status: { ...state.status, updateArticle: null, addCategory: null },
      };
    }
    case RESET_SPACE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default articles;
