const userReducer = (state = {list:[]}, action) => {
	switch (action.type) {
		case "SET_USERS":
			return { ...state, list:action.payload };
	   
		default:
			return state;
	}
};

export default userReducer;