import React from "react";
import ScrollToTop from "react-scroll-up";
import { Button } from "reactstrap";
import { ArrowUp } from "react-feather";
import classnames from "classnames";
import BuyNow  from "../BuyNow"
import {useSelector} from "react-redux";
import {useTrad} from "../../../utility/traduction"
import NavbarLangs from "../navbar/NavbarLangs"
import {Link} from "react-router-dom"

const Footer = (props) => {
  let footerTypeArr = ["sticky", "static", "hidden"];
  const lang = useSelector(state => state.traduction.lang);
  const t = useTrad("HibimSpaceAdmin_footer");
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static":
          props.footerType === "static" ||
          !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden",
      })}
      style={{fontSize:"1.2em"}}
    >
      <div className="d-flex justify-content-between">
        <div>
          COPYRIGHT © {new Date().getFullYear()}
          <a
            href={process.env.REACT_APP_HIBIM_URL}
            target="_blank"
            className="text-primary"
            rel="noopener noreferrer"
          >
            Hibim
          </a>
          {t('allrights', 'Tous droits réservés')}
        </div>
        <div className="d-flex align-items-center">
          <Link href={`${process.env.REACT_APP_POLICIES_URL}/${lang}`} className="text-primary">
            {t('policies', "Conditions d'utilisations")}
          </Link>
          <Link href={`${process.env.REACT_APP_HIBIM_URL}/${lang}/about`} className="text-primary">
            {t('about', "A propos")}
          </Link>
          <span className="mr-1">
            <NavbarLangs className="d-inline-block" />
          </span>
        </div>
      </div>
      {props.hideScrollToTop === false ? (
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : null}
       <BuyNow />
    </footer>
  );
};

export default Footer;
