import { getCurrentFromState as getCurrentSpaceFromState } from "../space";
import api from "utility/api"

export const REQUEST_GET_FORMDATA = "PAGES.REQUEST_GET_FORMDATA";
export const REQUEST_UPDATE_FORMDATA_STATUS = "PAGES.REQUEST_UPDATE_FORMDATA_STATUS";


export function getFormData(template) {
  return (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    dispatch({
      type: REQUEST_GET_FORMDATA,
      template,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/spaces/${currentSpace.id}/page_form_datas?template=${
          template || ""
        }`,
        method: "GET",
      },
    });
  };
}

export function updateFormDataStatus(id, status) {
  return (dispatch, getState) => {
    const currentSpace = getCurrentSpaceFromState(getState());
    if (!currentSpace) {
      return null;
    }
    dispatch({
      type: REQUEST_UPDATE_FORMDATA_STATUS,
      id,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/page_form_datas/${id}/${status}`,
        method: "PUT",
        data: {
          status,
        },
      },
    });
  };
}
