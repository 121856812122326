import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import throttle from "lodash/throttle";

import * as Icon from "react-feather";
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, NavItem, NavLink } from "reactstrap";

import { useTrad } from "utility/traduction";
import { logout, isLogged, isUserLoading, getCurrentUserFromState } from "redux/actions/auth";
import { getSearhResultFromState, requetsSearchTerm, getSearchLoadingStateFromState } from "redux/actions/space/space";

import Avatar from "components/hibimComponents/Avatar";
import Autocomplete from "components/@vuexy/autoComplete/AutoCompleteComponent";

import navigationConfig from "configs/navigationConfig";
import { actionsList } from "views/pages/space/action/actionsConfig";

const internalPages = [
  {
    groupTitle: "Actions",
    data: actionsList,
    searchLimit: 10,
    useDefaultFilter: true,
  },
  {
    groupTitle: "Pages",
    data: navigationConfig.filter(item => item.type === "item"),
    searchLimit: 10,
    useDefaultFilter: true,
  },
];

const UserDropdown = ({ currentUser, currentSpace }) => {
  const dispatch = useDispatch();
  const t = useTrad("HibimSpaceAdmin_header_UserDropdown");
  const user = useSelector(state => state.auth?.user);
  const lang = useSelector(state => state.traduction?.lang);
  const handleLogout = React.useCallback(
    e => {
      e.preventDefault();
      dispatch(logout());
    },
    [dispatch],
  );

  return (
    <DropdownMenu right style={{ minWidth: "250px" }}>
      <DropdownItem header>
        <span className="align-middle">
          Profile - {currentUser && currentUser.roles.indexOf("ROLE_ADMIN") !== -1 ? "Admin" : "User"} (u{" "}
          {currentUser ? currentUser.id : "unknown"})
        </span>
      </DropdownItem>
      <DropdownItem tag="a" href={`${process.env.REACT_APP_HIBIM_URL}/${lang}/u/${user?.userProfile?.id}`}>
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">{t("myProfile", "Mon profil")}</span>
      </DropdownItem>
      <DropdownItem tag="a" href={`${process.env.REACT_APP_MY_ACCOUNT_URL}?space=${currentSpace?.slug || ""}`}>
        <Icon.Settings size={14} className="mr-50" />
        <span className="align-middle">{t("myAccount", "Mon Compte")}</span>
      </DropdownItem>
      <DropdownItem tag="a" href={`${process.env.REACT_APP_ACCOUNTS_URL}/create`}>
        <Icon.MapPin size={14} className="mr-50" />
        <span className="align-middle">{t("addSpace", "Ajouter un space")}</span>
      </DropdownItem>
      <DropdownItem tag="a" href={`${process.env.REACT_APP_ACCOUNTS_URL}/join`}>
        <Icon.PlusCircle size={14} className="mr-50" />
        <span className="align-middle">{t("joinSpace", "Rejoindre un space")}</span>
      </DropdownItem>
      <DropdownItem tag="a" href={`${process.env.REACT_APP_ACCOUNTS_URL}/invite`}>
        <Icon.UserPlus size={14} className="mr-50" />
        <span className="align-middle">{t("inviteUser", "Inviter un utilisateur")}</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#" onClick={handleLogout}>
        <Icon.LogOut size={14} className="mr-50" />
        <span className="align-middle">{t("disconnect", "Déconnexion")}</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

const NavbarUser = ({ handleAppOverlay }) => {
  const dispatch = useDispatch();
  const { currentUser, currentSpace, searchResults, searchLoading } = useSelector(state => ({
    logged: isLogged(state),
    userLoading: isUserLoading(state),
    currentUser: getCurrentUserFromState(state),
    currentSpace: state.space.space?.current || {},
    searchResults: getSearhResultFromState(state),
    searchLoading: getSearchLoadingStateFromState(state),
  }));

  useEffect(() => {
    if (searchResults) {
      setSuggestions([...searchResults, ...internalPages]);
    }
  }, [searchResults]);

  const [navbarSearch, setNavbarSearch] = useState(false);
  const [suggestions, setSuggestions] = useState(internalPages);

  // eslint-disable-next-line
  const searchTerm = useCallback(
    throttle(term => dispatch(requetsSearchTerm(term)), 500),
    [dispatch],
  );

  const handleNavbarSearch = () => {
    setNavbarSearch(!navbarSearch);
  };

  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <NavItem className="nav-search" onClick={handleNavbarSearch}>
        <NavLink className="nav-link-search">
          <Icon.Search size={21} data-tour="search" />
        </NavLink>
        <div
          className={classnames("search-input", {
            open: navbarSearch,
            "d-none": navbarSearch === false,
          })}
        >
          <div className="search-input-icon">
            <Icon.Search size={17} className="primary" />
          </div>
          <Autocomplete
            className="form-control"
            suggestions={suggestions}
            filterKey="title"
            filterHeaderKey="groupTitle"
            grouped={true}
            placeholder="Rechercher..."
            autoFocus={true}
            clearInput={navbarSearch}
            loading={searchLoading}
            externalClick={e => {
              setNavbarSearch(false);
            }}
            onKeyDown={e => {
              if (e.keyCode === 27 || e.keyCode === 13) {
                setNavbarSearch(false);
                handleAppOverlay("");
              }
            }}
            onChange={e => {
              const userInput = e.target.value;
              if (userInput.length > 1) {
                searchTerm(userInput);
              }
            }}
            customRender={(item, i, filteredData, activeSuggestion, onSuggestionItemClick, onSuggestionItemHover) => {
              return (
                <li
                  className={classnames("suggestion-item", {
                    active: filteredData.indexOf(item) === activeSuggestion,
                  })}
                  key={i}
                  onClick={e => {
                    if (item["@type"] === "Task") {
                      onSuggestionItemClick("/demandes", e);
                    } else {
                      onSuggestionItemClick(item.navLink, e);
                    }
                  }}
                  onMouseEnter={() => onSuggestionItemHover(filteredData.indexOf(item))}
                >
                  <div
                    className={classnames({
                      "d-flex justify-content-between align-items-center": item.file || item.img,
                    })}
                  >
                    <div className="item-container d-flex">
                      {item.icon ? (
                        item.icon
                      ) : item.file ? (
                        <img src={item.file} height="36" width="28" alt={item.title} />
                      ) : item.img ? (
                        <img className="rounded-circle mt-25" src={item.img} height="28" width="28" alt={item.title} />
                      ) : null}
                      <div className="item-info ml-1">
                        <p className="align-middle mb-0">{item.title}</p>
                        {item.by || item.email ? (
                          <small className="text-muted">{item.by ? item.by : item.email ? item.email : null}</small>
                        ) : null}
                      </div>
                    </div>
                    {item.size || item.date ? (
                      <div className="meta-container">
                        <small className="text-muted">{item.size ? item.size : item.date ? item.date : null}</small>
                      </div>
                    ) : null}
                  </div>
                </li>
              );
            }}
            onSuggestionsShown={userInput => {
              if (navbarSearch) {
                handleAppOverlay(userInput);
              }
            }}
          />
          <div className="search-input-close">
            <Icon.X
              size={24}
              onClick={e => {
                e.stopPropagation();
                setNavbarSearch(false);
                handleAppOverlay("");
              }}
            />
          </div>
        </div>
      </NavItem>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600">
              {currentUser ? `${currentUser.firstname} ${currentUser.lastname}` : null}
            </span>
            <span className="user-status">{currentUser ? currentUser.email : null}</span>
          </div>
          <span className="user avatar m-0">
            <Avatar
              src={currentUser && currentUser.photo ? currentUser.photo.url : null}
              size="40px"
              alt={
                currentUser ? `${currentUser.firstname[0].toUpperCase()}${currentUser.lastname[0].toUpperCase()}` : null
              }
            />
          </span>
        </DropdownToggle>
        <UserDropdown currentUser={currentUser} currentSpace={currentSpace} />
      </UncontrolledDropdown>
    </ul>
  );
};

export default memo(NavbarUser);
