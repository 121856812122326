import { getCurrentFromState as getCurrentSpaceFromState } from "../space";
import { getCurrentUserFromState, updateCurrentUser } from "redux/actions/auth";
import { CONTACT_STATUS_ACCEPTED, CONTACT_STATUS_REJECTED } from "configs";
import api from "utility/api";

export const REQUEST_LIST_CHANNELS = "CHAT.REQUEST_LIST_CHANNELS";
export const REQUEST_LIST_CHATS = "CHAT.REQUEST_LIST_CHATS";
export const REQUEST_SEARCH_USERS = "CHAT.REQUEST_SEARCH_USERS";
export const REQUEST_CREATE_CHANNEL = "CHAT.REQUEST_CREATE_CHANNELS";
export const REQUEST_UPDATE_CHANNEL = "CHAT.REQUEST_UPDATE_CHANNEL";
export const REQUEST_DELETE_CHANNEL = "CHAT.REQUEST_DELETE_CHANNEL";
export const REQUEST_SEND_DEMAND = "CHAT.REQUEST_SEND_DEMAND";
export const REQUEST_APPROVE_DEMAND = "CHAT.REQUEST_APPROVE_DEMAND";
export const REQUEST_REJECT_DEMAND = "CHAT.REQUEST_REJECT_DEMAND";
export const REQUEST_DELETE_DEMAND = "CHAT.REQUEST_DELETE_DEMAND";
export const REQUEST_CREATE_MESSAGE = "CHAT.REQUEST_CREATE_MESSAGE";
export const REQUEST_LIST_MESSAGES = "CHAT.REQUEST_LIST_MESSAGES";
export const REQUEST_CONTACTS = "SPACES.REQUEST_CONTACTS";
export const ADD_CONTACT = "SPACES.ADD_CONTACT";
export const UPDATE_CONTACT = "SPACES.UPDATE_CONTACT";

export const SET_CURRENT_CHAT = "CHAT.SET_CURRENT_CHAT";
export const CLEAR_MESSAGES = "CHAT.CLEAR_MESSAGES";

export function requestChats() {
  return async (dispatch, getState) => {
    const state = getState();
    const currentUser = getCurrentUserFromState(state);
    const currentSpace = getCurrentSpaceFromState(state);
    if (!currentSpace || !currentUser) {
      return null;
    }

    dispatch({ type: REQUEST_LIST_CHATS, success: null });
    try {
      const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/users/${currentUser.id}/chats?space=${currentSpace.id}`,
      );
      dispatch({ type: REQUEST_LIST_CHATS, success: true, data: response.data });
      dispatch(updateCurrentUser({ chatUnreadCount: response.data.unreadCount }));
    } catch (e) {
      dispatch({ type: REQUEST_LIST_CHATS, success: false });
    }
  };
}

export function requestSearchUser(filter) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentUser = getCurrentUserFromState(state);
    const currentSpace = getCurrentSpaceFromState(state);
    if (!currentSpace || !currentUser) {
      return null;
    }

    dispatch({
      type: REQUEST_SEARCH_USERS,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/users?username=${filter}`,
        method: "GET",
      },
    });
  };
}

export function getUsersFromState(state) {
  return state.space?.chat?.users || [];
}

export function requestCreateMessage(data) {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = getCurrentUserFromState(state);
    const currentChat = getCurrentChatIdFromState(state);

    if (!currentUser || !currentChat) {
      return null;
    }
    if (currentChat.currentChatIsChannel) {
      dispatch({
        type: REQUEST_CREATE_MESSAGE,
        request: {
          url: `${process.env.REACT_APP_API_URL}/api/messages`,
          method: "POST",
          data: {
            ...data,
            channel: currentChat.currentChatId,
          },
        },
      });
    } else {
      dispatch({
        type: REQUEST_CREATE_MESSAGE,
        request: {
          url: `${process.env.REACT_APP_API_URL}/api/messages`,
          method: "POST",
          data: {
            ...data,
            receiver: currentChat.currentChatId,
          },
        },
      });
    }
  };
}

export function getSendingFromState(state) {
  const { space } = state;
  if (space && space.chat) {
    return space.chat.messageSending || false;
  }
  return false;
}

export function requestListMessages() {
  return (dispatch, getState) => {
    const state = getState();
    const currentChat = getCurrentChatIdFromState(state);
    if (!currentChat) {
      return;
    }

    if (currentChat.currentChatIsChannel) {
      const channelId = currentChat.currentChatId.replace("/api/channels/", "");
      dispatch({
        type: REQUEST_LIST_MESSAGES,
        request: {
          url: `${process.env.REACT_APP_API_URL}/api/messages?channel=${channelId}`,
          method: "GET",
        },
      });
    } else {
      const userId = currentChat.currentChatId.replace("/api/user/", "");
      dispatch({
        type: REQUEST_LIST_MESSAGES,
        request: {
          url: `${process.env.REACT_APP_API_URL}/api/messages?user=${userId}`,
          method: "GET",
        },
      });
    }
  };
}

export function setCurrentChat(id, isChannel = false) {
  return {
    type: SET_CURRENT_CHAT,
    payload: {
      id,
      isChannel,
    },
  };
}

export function getCurrentChatFromState(state) {
  const { space } = state;
  if (space && space.chat) {
    return space.chat.messages || [];
  }
  return [];
}

export function getCurrentChatIdFromState(state) {
  const { space } = state;
  if (space && space.chat && space.chat.currentChatId) {
    return {
      currentChatId: space.chat.currentChatId,
      currentChatIsChannel: space.chat.currentChatIsChannel,
    };
  }

  return null;
}

export function getMessagesLoadingFromState(state) {
  const { space } = state;
  if (space && space.chat) {
    return space.chat.messagesLoading || false;
  }
  return false;
}

export function clearMessages() {
  return {
    type: CLEAR_MESSAGES,
  };
}
export function requestCreateChannel(data) {
  return (dispatch, getState) => {
    const state = getState();
    const currentSpace = getCurrentSpaceFromState(state);
    if (!currentSpace) {
      return null;
    }

    dispatch({
      type: REQUEST_CREATE_CHANNEL,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/channels`,
        method: "POST",
        data: {
          ...data,
          channelUsers: data.channelUsers.map(u => ({ user: u })),
          space: currentSpace["@id"],
        },
      },
    });
  };
}

export function requestUpdateChannel(id, data) {
  return (dispatch, getState) => {
    const state = getState();
    const currentSpace = getCurrentSpaceFromState(state);
    if (!currentSpace) {
      return null;
    }

    dispatch({
      type: REQUEST_UPDATE_CHANNEL,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/channels/${id}`,
        method: "PUT",
        data,
      },
    });
  };
}

export function requestListChannels() {
  return (dispatch, getState) => {
    const state = getState();
    const currentSpace = getCurrentSpaceFromState(state);
    if (!currentSpace) {
      return null;
    }

    const spaceId = currentSpace["@id"].replace("/api/space/", "");
    dispatch({
      type: REQUEST_LIST_CHANNELS,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/channels?space=${spaceId}`,
        method: "GET",
      },
    });
  };
}

export function requestSendDemand(idChannel) {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = getCurrentUserFromState(state);

    dispatch({
      type: REQUEST_SEND_DEMAND,
      payload: {
        idChannel,
      },
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/channel_users`,
        method: "POST",
        data: {
          channel: idChannel,
          user: currentUser["@id"],
        },
      },
    });
  };
}

export function requestRejectDemand(idDemand, idChannel) {
  return {
    type: REQUEST_REJECT_DEMAND,
    payload: {
      idDemand,
      idChannel,
    },
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/channel_users/${idDemand}/${CONTACT_STATUS_REJECTED}`,
      method: "POST",
      data: {},
    },
  };
}

export function requestDeleteDemand(idDemand, idChannel) {
  return {
    type: REQUEST_DELETE_DEMAND,
    payload: {
      idDemand,
      idChannel,
    },
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/channel_users/${idDemand}`,
      method: "DELETE",
    },
  };
}

export function requestApproveDemand(idDemand, idChannel, idUser) {
  return {
    type: REQUEST_APPROVE_DEMAND,
    payload: {
      idDemand,
      idChannel,
      idUser,
    },
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/channel_users/${idDemand}/${CONTACT_STATUS_ACCEPTED}`,
      method: "POST",
      data: {},
    },
  };
}

export function requestContacts() {
  return (dispatch, getState) => {
    const state = getState();
    const user = getCurrentUserFromState(state);
    if (!user) {
      return null;
    }

    dispatch({
      type: REQUEST_CONTACTS,
      request: {
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/users/${user.id}/contacts`,
      },
    });
  };
}

export function addContact(target) {
  return {
    type: ADD_CONTACT,
    request: {
      method: "POST",
      target,
      url: `${process.env.REACT_APP_API_URL}/api/user_contacts`,
      data: {
        target,
      },
    },
  };
}

export function updateContact(id, data) {
  return {
    type: UPDATE_CONTACT,
    id,
    data,
    request: {
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/user_contacts/${id}`,
      data,
    },
  };
}

export function getChannelDemandingFromState(state) {
  const { space } = state;
  if (space && space.chat) {
    return space.chat.channelDemanding || false;
  }
  return false;
}

export function getContactsFromState(state) {
  return state.space?.chat?.contacts || [];
}

export function getChannelsFromState(state) {
  return state.space?.chat?.channels || [];
}

export function getChannelLoadingFromState(state) {
  const { space } = state;
  if (space && space.chat) {
    return space.chat.channelLoading || false;
  }
  return false;
}

export function requestDeleteChannel(idChannel) {
  return {
    type: REQUEST_DELETE_CHANNEL,
    payload: {
      idChannel,
    },
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/channels/${idChannel}`,
      method: "DELETE",
    },
  };
}
