export const REQUEST_GET_DASHBOARDS = "DASHBOARD.REQUEST_GET_DASHBOARDS";
export const REQUEST_UPDATE_CURRRENT = "DASHBOARD.REQUEST_UPDATE_CURRRENT";
export const REQUEST_UPDATE_COMPONENTS = "DASHBOARD.REQUEST_UPDATE_COMPONENTS";
export const SET_CURRENT_DASHBOARD = "DASHBOARD.SET_CURRENT_DASHBOARD";
export const ADD_DASHBOARD_COMPONENT = "DASHBOARD.ADD_DASHBOARD_COMPONENT";
export const REMOVE_DASHBOARD_COMPONENT = "DASHBOARD.REMOVE_DASHBOARD_COMPONENT";
export const ADD_DASHBOARD = "DASHBOARD.ADD_DASHBOARD";

export const getDashboards = () => {
  return async (dispatch, getState) => {
    // const components = getState().space.components.components.map(c => c.dashboard);
    // use mock
    // dispatch({ type: REQUEST_GET_DASHBOARDS,  });
    // dispatch(setCurrentDashboard(dashboards[0].id));
  };
};

export const addDashboard = dashboard => {
  return async (dispatch, getState) => {
    const id = 99;
    const dashboardUpdated = { id, components: [], ...dashboard };
    dispatch({ type: ADD_DASHBOARD, dashboard: dashboardUpdated });
    dispatch(setCurrentDashboard(id));
  };
};

export const setCurrentDashboard = id => {
  return async (dispatch, getState) => {
    const dashboard = getState().space.dashboards.dashboards.find(dash => dash.id === id);
    dispatch({ type: SET_CURRENT_DASHBOARD, dashboard });
  };
};

export const updateCurrentDashboard = dashboards => {
  return { type: REQUEST_UPDATE_CURRRENT, dashboards };
};

export const updateComponents = components => {
  return { type: REQUEST_UPDATE_COMPONENTS, components };
};

export const addDashboardComponent = component => {
  return { type: ADD_DASHBOARD_COMPONENT, component };
};

export const removeDashboardComponent = component => {
  return { type: REMOVE_DASHBOARD_COMPONENT, component };
};
