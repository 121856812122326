import axios from "./api";
import { componentTitles, componentSizes } from "../configs";

export const fetchComponents = async ({ spaceId, view }) => {
  try {
    let components = await axios.get("/api/components/space/" + spaceId).then(res => res.data);

    if (components) {
      // retrieve actions
      components = components
        .filter(e => e.view === view)
        .map(e => {
          return {
            ...e,
            title: componentTitles[e.name] || e.name,
            size: componentSizes[e.name] || "full",
          };
        });

      return components;
      //  console.log("fetch components for view :", view, " result :", components);
    }
  } catch (e) {
    console.log("error fetching components");
  }
};

